$SIGNATURE_COLOR: rgb(0, 176, 240);
$PAPER_MARGIN: 20px;

.District{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    background-color: rgb(247, 247, 247);

    .Area{
        margin: $PAPER_MARGIN;
        max-width: 1500px;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
        width: calc(100% - #{$PAPER_MARGIN} * 2);

        .HeadTitle{
            font-size: 2rem;
            font-weight: bold;
        }
        .Title{
            font-size: 1.3rem;
            font-weight: bold;
            margin-bottom: $PAPER_MARGIN;
        }
        .ViewCount{
            font-size: 2.5rem;
            font-weight: bold;
        }
        .ContentText{
            font-size: 0.9rem; 
        }
        .SubText{
            font-size: 0.8rem;
            color: rgb(130, 130, 130);
            margin-top: $PAPER_MARGIN/4;            
        }
        .SubTitle{
            font-size: 1.1rem;
            font-weight: bold;
        }

        .Header{
            margin-bottom: $PAPER_MARGIN;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            
        
                .PublishIcon{
                    background-color: rgb(255, 255, 255);
                    color: $SIGNATURE_COLOR;
                    border: 1px rgba(0, 0, 0, 0.12) solid;
                    border-radius: 45%;
                    margin-right: $PAPER_MARGIN/2;
                    font-size: 2.5rem;
                }
                .RecordVoiceOverIcon{
                    background-color: rgb(255, 255, 255);
                    color: $SIGNATURE_COLOR;
                    border: 0.1px rgba(0, 0, 0, 0.12) solid;
                    border-radius: 45%;
                    font-size: 2.5rem;
                }
        }
        
        
        .Body{
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            @media (max-width: 720px) {
                flex-direction: column;
            }
            @media (min-width: 721px) {
                flex-direction: row;
            }

            .ContentBoxes{

                @media (max-width: 720px) {
                    width: 100%;
                }
                @media (min-width: 721px) {
                    width: calc( (100%/3) - 13px);
                }
                
                .ViewChart{
                    height: 50px;
                }


            }

            .WhitePaper{
                background-color: rgb(255,255,255);
                border: 1px rgba(0, 0, 0, 0.12) solid;
                margin-bottom: 20px;
                border-radius: 5px;
                
                .ImgForm{
                    display:flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border: 0.3px rgba(0, 0, 0, 0.12) solid;
                    width: calc(100% - #{$PAPER_MARGIN});
                    margin: 10px;
                    padding-top: 200px;
                    padding-bottom: 200px;
                    box-sizing: border-box;
                }

                .DivisionBlock{
                    border-bottom: 1px rgba(0, 0, 0, 0.12) solid;
                    margin-left: $PAPER_MARGIN;
                    margin-right: $PAPER_MARGIN;
                }

                .PaperFactors{
                    margin: $PAPER_MARGIN;
                }
                
                .Mesurements{
                    font-size: 1rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: stretch;
                    margin-top: $PAPER_MARGIN/2;
                }

                .InsiderNavigation{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: stretch;
                }
                .VideoTitle{
                    font-size: 1.1rem;
                    font-weight: bold;
                    margin-top: $PAPER_MARGIN/2;
                    margin-bottom: $PAPER_MARGIN/2;
                }
                .VideoExplantaion{
                    font-size: 0.9rem;
                    color: rgb(160, 160, 160);
                }
            }
        }

        .Footer{
            .AidButton{
                color: rgb(130, 130, 130);
                font-size: 0.8rem;
                margin-right: $PAPER_MARGIN;
            }
        }

        .ColoredButton{
            background-color: $SIGNATURE_COLOR;
            margin-top: $PAPER_MARGIN;
            font-size: 0.8rem;
        }
    }
}