
.SummaryTableArea{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 28px;

    .WebtoonDiagnosis{
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 12px;

        .WebtoonTitle{
            display: flex;
            justify-content: space-between;
            padding: 8px;
            background-color: rgba(242, 242, 247, 1);
            border-radius: 8px;

            .TitleText{
                display: flex;

                .Title, .Target{
                    text-align: center;
                    font-size: 0.9375rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px; /* 142.857% */
                    letter-spacing: 0.14px;
                    color: rgba(60, 70, 81, 1);
                }
                .Target::after{
                    display: inline-block;
                    content: '|';
                    margin: 0 5px;
                }
                .Title{
                    color: rgba(127, 144, 164, 1);
                }
            }

            .InfoContainer{
                position: relative;
                width: 18px;
                height: 18px;
                vertical-align: middle;
                
                .InfoCircleIcon{
                    cursor: pointer;
                }
                .FloatMessage{
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    transform: translateY(-30px);
                    color: rgb(255,255,255);
                    width: max-content;
                    word-break: keep-all;
                    font-size: 0.75rem;
                    line-height: 140%;
                    box-shadow: 0px 4px 3px 0px rgba(12, 22, 39, 0.08);
                    border-radius: 8px;
                    white-space: pre-line;
                    padding: 8px 16px;
                    background-color: rgba(60,70,81,0.9);

                    &::after {
                        content: "";
                        position: absolute;
                        top: 100%;
                        right: 5px;
                        margin-left: -10px; /* 꼬다리의 폭을 설정합니다. */
                        border-width: 10px;
                        border-style: solid;
                        border-color: rgba(60,70,81,0.9) transparent transparent transparent;
                        transform: skew(48deg, 0)
                    }

                    @container (width <= 700px) {
                        max-width: 280px;
                        &.pc{
                            display: none;
                        }
                    }
                    @container (width >  700px) {   
                        max-width: 400px;
                        &.mobile{
                            display: none;
                        }
                    }
                }
            }
        }
        .WebtoonResults{
            display: flex;
            gap: 12px;
            flex: 1;

            .ExposureResult, .ProfitabilityResults{
                display: flex;
                flex-direction: column;
                background-color: rgb(249, 251, 255);
                border: 1px solid rgba(236, 237, 243, 1);
                border-radius: 4px;
                flex: 1;

                .Row{
                    padding: 12px 20px;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    @container (width <= 700px) {
                        padding: 6px 10px;
                    }
                    .ArrowUpIcon{
                        width: 20px; height: 20px;
                        @container (width <= 700px) {
                            width: 16px; height: 16px;
                        }
                    }
                }
                .Row:not(:last-child){
                    border-bottom: 1px solid rgba(236, 237, 243, 1);
                }
                .Exposure, .Profitability{
                    align-items: center;
                    flex-direction: row;
                    justify-content: flex-start;
                    gap: 6px;
                    flex: 0.5;

                    .TrendBox{
                        display: flex;
                        align-items: center;
                        .TrendValue{
                            font-size: 0.8125rem;
                            color: rgba(60,70,81,1);
                            font-style: normal;
                            font-weight: 700;
                            line-height: 20px; /* 153.846% */
                            letter-spacing: 0.13px;

                            @container (width <= 700px) {
                                font-size: 0.75rem;
                            }
                        }
                    }
                }

                .StandardName{
                    color: rgba(127, 144, 164, 1);
                    font-size: 0.8125rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 153.846% */
                    letter-spacing: 0.13px;

                    @container (width <= 700px) {
                        font-size: 0.75rem;
                    }
                }
                .StandardValue{
                    color: rgba(60,70,81,1);
                    text-align: right;
                    font-size: 0.8125rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px; /* 153.846% */
                    letter-spacing: 0.13px;
                    
                    @container (width <= 700px) {
                        font-size: 0.75rem;
                    }
                }
                .SearchResultFlexCell{
                    display: flex;
                    justify-content: flex-end;
                    gap: 4px;
                }
            }
        }
    }
}