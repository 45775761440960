.TitleList {
    max-width: 1500px;
    margin: auto;
    background-color: rgba(244,244,244,1);

    .SearchArea{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        padding: 30px 20px 20px 10px;
        
        .SearchBar{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            padding: 5px;
            border-radius: 10px;
            background: rgb(255, 255, 255);
            border: 1px solid rgb(223, 225, 229);

            .SearchIcon{
                margin-left: 5px;
                margin-right: 10px;
            }

            .SearchField{
                ::before{
                    border-color: rgba(0,0,0,0);
                }
                ::after{
                    border-color: rgba(0,0,0,0);
                }
            }
        }
    }

    .SortingFunctions{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        max-width: 1350px;
        margin: auto;

        .Focusing{
            font-weight: bold;
        }
    
        .SelectBox{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
    
            .MuiInput-input{
                font-size: 0.875rem;
                padding: 0px 24px 0px 0px;
            }
        }
    }

    .TitleListContainer {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;

        .CellContainer {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            .TitleCell {
                padding: 0;
                margin: 5px;
                
                width: 120px;
                height: 120px;
                border-radius: 20px;
                overflow: hidden;

                display: flex;
                flex-direction: column;
                justify-content: stretch;
                align-items: stretch;

                span{
                    flex-direction: column;
                }

                .CellTitle {
                    box-sizing: border-box;
                    width: 100%;
                    padding: 9px;
                    text-align: center;
                    font-size: 0.8rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .TitleListTail {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .NoTitleWarning {
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            svg {
                width: 50px;
                height: 50px;
                padding: 20px;
            }
        }
    }
}