$CELL_SIZE: 100px;

.Process {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;

    .ControlBox {
        padding: 20px;
    }
    
    .StanbyZone {
        padding: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

    }
    .Chart {

        width: calc(#{$CELL_SIZE} * 9);
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .Row {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;

            .Col {

                width: $CELL_SIZE;
                height: $CELL_SIZE;
                border: 1px lightgray solid;
                box-sizing: border-box;

                text-align: center;

                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-around;
                word-break: keep-all;

                &.Header {
                    line-height: initial;
                    justify-content: center;
                }
            }
        }
    }

    .List {
        padding: 20px;

        .AreaHeader {
            div{
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: flex-start;
                padding-right: 10px;
            }
        }
        .AreaBody {
            padding-Left: 20px;
            
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
        
        }
    }
    
    .ButtonHolder {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: stretch;
        width: 100px;
        padding: 0;
        cursor: pointer;

        .Avatar {
            margin: 2px;
            flex-shrink: 1;
            flex-shrink: 0;
            width: 20px; height: 20px;
        }

        .ButtonText {
            font-size: 0.6rem;
            text-overflow: ellipsis;
            max-width: 74px;
            max-height: 20px;
            overflow-y: hidden;
            text-align: left;
            white-space: nowrap;
        }

        .DeleteButton {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0px;
        }
    }
    
    @media (max-width:calc(#{$CELL_SIZE} * 9 + 50px)) {
        .HiddenForNarrowDevice, .Chart{
            display: none;
        }
    }

    @media (min-width:calc(#{$CELL_SIZE} * 9 + 51px)) {
        .List{
            display: none;
        }
    }
    
}