.TopicTagging {
    .ControlBox {
        position: sticky;
        width: 100%;
        top: 0px;
        min-height: 70px;
        padding: 50px 10px 10px 10px;
        box-sizing: border-box;
        background-color: white;
        border-bottom: 1px lightgray solid;
        z-index: 2;

        // :nth-child() {
        //     margin-right: 10px;
        // }
    }
    .TopicTaggingBody {
        padding-top: 80px;
        .Webtoon {
            width: 100%;
            max-width: 1600px;
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            align-items: stretch;
            .ThumbnailCol {
                position: relative;
                width: 200px;
                height: 200px;
                box-sizing: border-box;
                padding: 20px;
                flex-shrink: 0;

                img {
                    width: 100%;
                    max-height: 100%;
                    object-fit: cover;
                }
            }
            .ContentsCol {
                width: 70%;
                box-sizing: border-box;
                padding: 20px;
                flex-shrink: 0;

                .Title {
                    font-size: 1.2rem;
                    font-weight: bold;
                }

                .Topics {
                    div {
                        margin: 5px;
                    }
                }
            }
        }
    }

    .DialogBody {
        *[role="button"] {
            margin: 5px;
        }
    }
}