$SIGNATURE_COLOR: rgb(0, 176, 240);
$SIGNATURE_BG_COLOR: rgba(0, 176, 240, 0.2);

.SurveyBody {
    background-color: $SIGNATURE_BG_COLOR;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .SurveyContainer {
        max-width: 800px;

        @media(max-width: 720px){
            width: 100%;
        }
    }

    .Section{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        word-break: keep-all;

        box-sizing: border-box;
        border: 0.5px lightgray solid;
        padding: 20px;
        margin-bottom: 20px;

        .Inner {

            width: 100%;

            table {
                width: 100%;
                table-layout: fixed;
                text-align: center;
            }
        
            .HorizontalRadioGroup {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: stretch;
                flex-wrap: nowrap;
                flex-shrink: 1;
                text-align: center;
                vertical-align: bottom;

                .RadioCell{
                    
                    @media(max-width: 720px){
                        width: 5%;
                    }
                }
                .OptionText{
                    margin-top: 4.8%;
                    
                    @media( max-width: 720px ){
                        margin-top: 0%;
                        width: 10%;
                        word-break: break-all;
                        text-align: center;
                    }
                }

                label {
                    margin: 0;

                    span {
                        padding: 2px;
                    }
                }
            }
            
            .Title {
                margin-bottom: 10px;
                display: flex;
                align-items: stretch;
                justify-content: stretch;
                flex-direction: row;
                
                @media (max-width: 720px) {
                    flex-direction: column;
                    align-items: flex-end;
                }
                
                .ButtonBox {
                    @media (max-width: 720px) {
                        margin-top:10px;
                    }
                    
                    max-width: 100px;
                }

                .RedAsterisk{
                    color: red;
                }
            }

            .Subtitle {
                font-size: 0.8rem;
                text-align: left;
                line-height: 130%;
            }

            .OptionText {
                font-size: 0.9rem;
                text-align: left;
            }

            .SubjectiveButton {
                margin-bottom: 10px;
            }

            
            .SubmittedTitle {
                font-size: 1.5rem;
                text-align: center;
                font-weight: bold;
            }

            .SubmittedContents {
                text-align: center;
            }

            .BoldText{
                font-weight: bold;
            }

            .SignatureColor{
                color: $SIGNATURE_COLOR;
            } 

            .Image{
                display: flex;
                justify-content: center;
                margin: 20px 0px;
            }

            .LogoImage{
                display: flex;
                justify-content: center;
                margin: 20px 0px;
                
                .LogoBackground{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    width: 200px;
                    height: 200px;
                    border-radius: 110px;
                    background-color: $SIGNATURE_BG_COLOR;

                    .Logo{
                        width: 130px;
                        padding-left: 18px;
                    }
                }
            }

            .Content {
                display: flex;
                flex-direction: row;
                justify-content: stretch;
                align-items: stretch;
                overflow-y: hidden;
                line-height: 130%;

                @media (max-width: 720px) {
                    flex-direction: column;
                }

                .Thumbnail {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 200px;
                    height: 200px;
                    flex-shrink: 0;

                    @media (max-width: 720px) {
                        flex-direction: row;
                        width: 100%;
                    }
                    img {
                        max-width: 100%;

                        @media (max-width: 720px) {
                            max-height: 200px;
                            max-width: initial;
                        }

                        object-fit: contain;
                        object-position: center;
                    }
                }
                .TextContent {
                    padding: 20px;
                    font-size: 0.8rem;

                    .Description, .Episodes {
                        padding: 10px 0 10px 0;
                    }
                }
            }

            
        }
    }

    .SurveyLogo{
        border: 0px;
        
        .Inner{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .WebtoonToday{
                max-width: 60%;
            }
        }
    }

    .ButtonBox{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
    }

    .SubmittedPaper {
        padding: 50px 30px;
    }
}