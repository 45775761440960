.PageContainer {
    width: 100%;
    min-width: 0;
    overflow: hidden;

    .MemberList {
        width: 200%;
        min-width: 0;
        display: flex;
        transition-duration: 0.2s;

        &.Detail {
            transform: translateX(-50%);
        }

        .MemberPage {
            width: 50%;
            max-height: calc(100vh - 48px);

            display: flex;
            flex-direction: column;
            overflow: auto;
            scrollbar-width: thin;

            .SearchArea {
                padding: 30px 20px 20px 10px;

                display: flex;
                justify-content: center;
                align-items: flex-end;
                gap: 5px;

                .SearchBar {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: flex-end;
                    padding: 5px;
                    border-radius: 10px;
                    background: rgb(255, 255, 255);
                    border: 1px solid rgb(223, 225, 229);

                    .SearchIconButton {
                        padding: 5px;
                    }

                    .SearchField {
                        ::before {
                            border-color: rgba(0, 0, 0, 0);
                        }
                        ::after {
                            border-color: rgba(0, 0, 0, 0);
                        }
                    }
                }

                .SelectBox {
                    min-width: 80px;
                    padding: 6px;
                }
            }

            .MemberListContainer {
                width: 100%;
                
                table {
                    width: 100%;
                    table-layout: auto;
                    min-width: 1200px;
                    overflow-x: auto;
                    scrollbar-width: thin;

                    thead {

                        tr {
                            background-color: lightgrey;

                            th {
                                padding: 0 10px;
                                font-size: 0.875rem; // 14px
                            }
                        }

                        tr:nth-child(odd) {
                            background-color: gray;
                        }
                    }

                    tbody {
                        tr:nth-child(even) {
                            background-color: rgba(241, 233, 233, 0.475);
                        }
                        
                        tr {
                            cursor: pointer;
                            transition-duration: 0.3s;

                            &:hover {
                                background-color: rgba(0, 0, 0, 0.055);
                            }

                            td {
                                max-width: 600px;
                                padding: 4px 10px;

                                text-align: center;
                                white-space: nowrap; 
                                text-overflow: ellipsis;
                                overflow: hidden;
                                font-size: 0.9375rem; // 15px

                                .profileImage {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;

                                    img {
                                        width: 40px;
                                    }

                                }
                            }
                        }
                    }
                }
            }

            .MemberInfoContainer {
                padding-top: 10px;
                box-sizing: border-box;
                overflow: auto;
                scrollbar-width: thin;
                white-space: nowrap;

                .MuiButton-root {
                    min-width: 0;

                    .BackButton {
                        width: 36px;
                    }
                }

                span {
                    font-size: 1.125rem; // 18px
                }

                .MuiDialogTitle-root {
                    padding-left: 40px;
                }

                .MemberProfile {
                    padding: 0 10px;
                    display: flex;
                    gap: 20px;
                    flex: 1;
                    margin-bottom: 20px;

                    .ProfileImage {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        
                        img {
                            width: 150px;
                        }
                    }

                    table, th, td {
                        border: 1px solid rgb(66, 68, 78);
                    }

                    table {
                        font-size: 0.875rem; // 14px 
                        line-height: 22px;
                        border-collapse: collapse;
                        min-width: 380px;

                        caption {
                            font-size: 0.9375rem; // 15px
                        }

                        tr {
                            th {
                                padding: 0 10px;
                                width: 73px;
                                background: rgb(234, 234, 237);
                            }
                            td {
                                max-width: 450px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                padding: 2px 8px;
                            }
                        }
                    }
                }

                .MemberInfoTable {
                    padding: 0;
                    overflow: visible;

                    table {
                        width: 100%;
                        table-layout: auto;
                        box-sizing: border-box;
                        border-collapse: collapse;
            
                        thead {
                            tr, th {
                                border: 1px solid rgb(198, 201, 204);
                            }

                            tr {
                                background-color: rgb(197, 197, 197);
            
                                th {
                                    background: rgb(66, 68, 78);
                                    color: rgb(255, 255, 255);

                                    padding: 3px 8px;
                                    white-space: nowrap;
                                    font-size: 0.8125rem; // 13px
                                    font-weight: 500;                
                                }
                            }
                            
                            tr:nth-child(even) {
                                background-color: rgb(232, 232, 232);
                            }
                            
                        }

                        tbody {
                            tr, td {
                                border: 1px solid rgb(198, 201, 204);
                            }

                            tr {
                                td {
                                    padding: 3px 15px;
                                    max-width: 400px;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    font-size: 0.8125rem; // 13px

                                    img {
                                        width: 30px;
                                    }
                                }
                            }

                            tr:hover {
                                background: rgb(234, 234, 237);
                            }
                        }
                    }
                }
            }
        }
    }
}

.ListInfoDialog {

    .ListInfoDialogPaper {
        margin: 0;
        padding: 30px;
        height: auto;
        max-width: 1200px;

        overflow:hidden;

        .CloseButton {
            min-width: 0;
            width: 28px;
            height: 28px;
    
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
    
            span img {
                width: 22px;
                height: 22px;
            }
        }
        
        table {
            width: 100%;
            table-layout: auto;
            box-sizing: border-box;
            border-collapse: collapse;

            thead {
                tr, th {
                    border: 1px solid rgb(198, 201, 204);
                }

                tr {
                    background-color: rgb(197, 197, 197);

                    th {
                        background: rgb(66, 68, 78);
                        color: rgb(255, 255, 255);

                        padding: 3px 8px;
                        white-space: nowrap;
                        font-size: 0.8125rem; // 13px
                        font-weight: 500;                
                    }
                }
                
                tr:nth-child(even) {
                    background-color: rgb(232, 232, 232);
                }
                
            }

            tbody {
                tr, td {
                    border: 1px solid rgb(198, 201, 204);
                }

                tr {
                    td {
                        padding: 3px 15px;
                        font-size: 0.8125rem; // 13px

                        img {
                            width: 30px;
                        }
                    }
                }

                tr:nth-child(even) td {
                    background: rgb(234, 234, 237);
                }
            }
        }
    }
}