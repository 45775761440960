@import './Analytics/ChannelAnalytics/ChannelAnalyticsFontVariables.scss';
$SIGNATURE_COLOR: rgb(0, 176, 240);

.ReportForOthers {

    max-width: 1500px;
    margin: auto;
        
    .TitleInfoContainer{
        margin-top: 62px;
        margin-left: auto; margin-right: auto;
        max-width: 1200px;

        .TitleInfo {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin: 20px;

            @media(max-width: 720px){
                align-items: center;
            }

            .Col {
                padding: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;

                .MainThumbContainer {
                    position: relative;
                    width: 100%;
                    margin-left: auto; margin-right: auto;
                    border-radius: 5px;
                    overflow: hidden;
                    background-size: cover;
                    background-position: center;
                    padding-top: calc(min(100%, 300px));
                    max-width: 300px;

                    .MainThumbBackdrop {
                        position: absolute;
                        top:0; left:0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        backdrop-filter: blur(10px);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .MainThumb {
                    max-height: 300px;
                    max-width: 300px;
                    object-fit: contain;

                }

                .VisibilityInfo {
                    padding: 7px;
                    width: calc(100% - 14px);
                }

                .TextField {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                .AdsContainer {
                    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
                }

                .Slides {
                    padding: 5px;
                    width: calc(100% - 14px);
                    height: 120px;
                    white-space: nowrap;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    overflow-x: auto;
                    overflow-y: hidden;
                    
                    &::-webkit-scrollbar {
                        all: initial;
                        height: .8rem;
                        width: .8rem;
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        background-color: rgb(150,150,150);
                        border-radius: 1rem;
                    }

                    .SlideThumb {
                        position: relative;
                        flex-shrink: 0;
                        flex-grow: 0;
                        width: 100px; height: 100px; margin: 5px;
                        font-size: 3rem;

                        span {
                            color: rgba(233,233,233,1);
                        }
                    }
                }
            }
        }
    }
    .RankContainer {
        margin: auto;
        max-width: 1200px;
        .Rank {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: stretch;
            padding-bottom: 20px;
    
            .Col {
                text-align: center;
    
                .Number {
                    font-size: 2rem;
                    font-weight: bolder;
                }
            }
        }
    }
    .FeedbackReport {
        margin: auto;
        max-width: 1200px;

        .Feedback {
            width: calc(100% - 40px);
            min-height: 100px;
            margin: 20px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            align-items: stretch;
            padding: 10px;

            .Thumb {
                max-width: 100px;
                max-height: 100px;

                .Profile {
                    width: 80px;
                    height: 80px;
                    margin: 10px;
                }
                .Name {
                    text-align: center;
                    word-break: keep-all;
                    font-weight: bold;
                }
            }

            .Text {
                flex-shrink: 1;
                width: 100%;
                
                display: flex;
                flex-direction: column;

                .Title {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    border-bottom: 1px lightgray solid;
                    
                    .Btn {
                        padding: 5px;
                        border-radius: 0;

                        $BORDER_RADIUS: 5px;

                        &:first-child {
                            border-top-left-radius: $BORDER_RADIUS;
                            border-bottom-left-radius: $BORDER_RADIUS;
                        }
                        &:last-child {
                            border-top-right-radius: $BORDER_RADIUS;
                            border-bottom-right-radius: $BORDER_RADIUS;
                        }
                    }
                }
            }
        }
    }

    .FunnelReport {

        max-width: 1200px;
        margin: auto;
        
        .ViewBox{
            margin: 20px;
            border: 1px solid rgba(192, 192, 192, 1);
        
            .ViewBar{
                    
                .MuiTabs-flexContainer{
                    @media (max-width: 720px) {
                        flex-direction: column;
                        height: 200px;
                    }
                    @media (min-width: 721px) {
                        flex-direction: row;
                        height: 100px;
                    }  
                }
            }
        }

        .ReachBox{
            margin: 20px;
            border: 1px solid rgba(192, 192, 192, 1);

            .MuiTabs-flexContainer{
                @media (max-width: 720px) {
                    flex-direction: column;
                    height: 200px;
                }
                @media (min-width: 721px) {
                    flex-direction: row;
                    height: 100px;
                }
            }
        }

        .EngageBox{
            border: 1px solid rgba(192, 192, 192, 1);
            margin: 20px;

            .MuiTabs-flexContainer{
                @media (max-width: 720px) {
                    flex-direction: column;
                    height: 200px;
                }
                @media (min-width: 721px) {
                    flex-direction: row;
                    height: 100px;
                }

            }
        }

        .AudienceBox {
            margin: 20px;

            .MainChart {
                display: flex;
        
                @media (min-width: 721px) {
                    flex-direction: row;
        
                    .DoughnutArea {
                        width: calc(50% - 12px);
                        max-height: 300px;
                    }
                }
                @media (max-width: 720px) {
                    flex-direction: column;
                }
        
                .CohortTable {
                    width: calc(50% - 12px);
                    display: flex;
                    flex-direction: column;
                    justify-content: stretch;
                    align-items: stretch;
                    
                    @media (max-width: 720px) {
                        margin-top: 12px;
                        width: calc(100% - 12px);
                    }
        
                    .Row {
                        width: 100%;
                        height: 40px;
                        display: flex;
                        flex-direction: row;
                        justify-content: stretch;
                        align-items: stretch;
                        
                        .AgeScope{
                            margin: auto;
                            padding: 0 3px;
                            font-size: 0.8rem;
                            min-width: 42px;
                            width: 42px;
                            text-align: center;
                            word-break: break-all;
                        }

                        .Cell {
                            width: 100%;
                            box-sizing: border-box;
                            border: 1px rgba(0,0,0,0) solid;
                            overflow-x:hidden;
        
                            div {
                                transition: 200ms;
                                flex-shrink: 0;
        
                                &:last-child {
                                    flex-shrink: 1;
                                }
                            }
        
                            &.Female {
                                display: flex;
                                flex-direction: row-reverse;
        
                                div {
                                    min-width: 1px;
                                    height: 100%;
        
                                    &:first-child {
                                        background-color: rgba(255,99,132,1);
                                    }
        
                                    &:last-child {
                                        background-color: rgba(255,99,132,0.4);
                                    }
                                }
                            }
        
                            &.Male {
                                display: flex;
                                flex-direction: row;
        
                                div {
                                    min-width: 1px;
                                    height: 100%;
        
                                    &:first-child {
                                        background-color: rgba(54,162,235,1);
                                    }
        
                                    &:last-child {
                                        background-color: rgba(54,162,235,0.4);
                                    }
                                }
                            }
        
                            &.Unknown {
                                width: 20%;
                                flex-grow: 0;
                                display: flex;
                                flex-direction: row-reverse;
        
                                div {
                                    min-width: 1px;
                                    height: 100%;
                                    &:first-child {
                                        background-color: rgba(100,100,100,1);
                                    }
        
                                    &:last-child {
                                        background-color: rgba(100,100,100,0.4);
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
        
        .ChartAreaTab{
            font-size: $FONT_SIZE_H4;
            font-weight: bold;
            text-transform: None;
            background-color: rgba(245, 245, 245, 1 );

            &.Active{
                background-color: rgba(255, 255, 255, 1);
                width: 100%;
            }
            &.Active::before {
                position: absolute;
                display: block;
                content: "";
                width: 100%;
                top: 0;
                height: 5px;
                background-color: rgb(0, 176, 240);
                box-sizing: border-box;

                @media (min-width: 721px) {
                    top: 0;
                    height: 5px;
                    background-color: rgb(0, 176, 240);
                    width: 100%;
                }
                @media (max-width: 720px) {
                    left: 0;
                    width: 10px;
                    background-color: rgb(0, 176, 240);
                    height: 100%;
                }
            }
        }
    }

    .DeepdiveReport {
        max-width: 1200px;
        margin-left: auto; margin-right: auto;
        .Score {
            .MuiInput-root, .MuiButtonBase-root {
                display: none;
            }

            padding: 10px;
        }

        &.Hidden {
            div.Chart, div.Score, div.NextEpButton {
                opacity: 0;
                z-index: -2;
            }
        }
        div.Chart, div.Score {
            opacity: 1;
            transition: 200ms;
        }

    }

    .QuestionModal{
        color: $SIGNATURE_COLOR;
        cursor: pointer;
        vertical-align: bottom;

        .QuestionButton{
            font-size: 0.8rem;
            width: 18px;
            height: 18px;
            padding: 2px;
            color: $SIGNATURE_COLOR;
            border: 1px solid $SIGNATURE_COLOR;
            margin-left: 5px;
        }

        .QuestionTitleButton{
            font-size: 1.2rem;
            width: 28px;
            height: 28px;
            padding: 2px;
            color: $SIGNATURE_COLOR;
            border: 1px solid $SIGNATURE_COLOR;
            margin-left: 10px;
            margin-bottom: 1px;
        }
    }
}

@media (min-width: 720px) {
    .TitleInfo {
        flex-direction: row;
        align-items: stretch;

        .Col {
            width: 50%;
            
            &.WithPreview {
                max-width: 300px;
            }            
        }
    }
}

@media (max-width: 719px) {
    .TitleInfo {
        flex-direction: column;

        .Col {
            width: 100%;
        }
    }
}