
/**
 * 
 * @param {number} parentWidth 
 * @param {number} parentHeight 
 * @param {number} scrollY 
 */
 .DetailStyle {
    @media (max-width: 640px) { //narrow
        left: 0;

        .DetailHeader {
            
            .Slider{
                padding-top: 600px;
                height: calc(100% - 400px);
                padding-bottom: 36px;
                background-image: linear-gradient(
                    to bottom,
                    rgba(0,0,0,0.4) 0%,
                    rgba(0,0,0,0) 10%,
                    rgba(0,0,0,0) 30%,
                    rgba(0,0,0,0.4) 40%,
                    rgba(0,0,0,1) 60%,
                    rgba(0,0,0,1)
                );

                
                .ThumbnailContainer {
                    min-height: calc(100% * 9 / 16);
                }
            }
        }
    }

    @media (min-width: 641px) {
        width: 50%;
        padding-left: 50%;

        .DetailHeader {
            left: 0;
            width: 50%;
            max-width: 420px;
            position: fixed;

            .Slider {
                padding-top: 400px;
                height: calc(100% - 400px);
                background-image: linear-gradient(
                    to bottom,
                    rgba(0,0,0,0.4) 0%,
                    rgba(0,0,0,0) 10%,
                    rgba(0,0,0,0) 20%,
                    rgba(0,0,0,0.4) 25%,
                    rgba(0,0,0,1) 50%,
                    rgba(0,0,0,1)
                );
            }
        }
    }

    @media (min-width: 641px) and (max-width: 840px) { //wide

        .DetailHeader {
            top: -3rem;
        }
    }

    @media (min-width: 841px) and (max-width: 900px) { //wide

        .DetailHeader {
            top: 0;
            margin-left: calc(50% / 2 - 420px / 2);
        }
    }

    @media (min-width: 901px) {
        width: calc(100% - 500px);
        padding-left: 500px;
        
        .DetailHeader {
            top: 0;
            margin-left: 30px;
        }
    }

    padding-top: 3rem;
    height: 100%;
    position: relative;
    .DetailHeader {
        height: calc(100% + 3rem);
        background-color: black;
        overflow-x: hidden;
        .Background {
            top: 3rem;
            left: 0%;
            width: 100%;
            padding-top: 120%;
            position: absolute;
            background-position: top;
            background-size: cover;
            background-repeat: no-repeat;
        }
        .Slider{
            position: relative;
            color: white;

            .TopButtonContainer {
                position: absolute;
                top: 0; left: 0;
                display: flex;
                width: 100%;
                .BackButton {
                    text-align: center;
                    padding-top: 10px;
                    padding-left: 10px;
                };
                
            };
            .Title {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                height: 2.8rem;
                padding: 10px;
                font-size: 1.4rem;
                font-weight: bolder;
            };
            .Description {
                color: rgba(220,220,220,1);
                font-size: 1rem;
                line-height: 1.4rem;
                word-break: keep-all;
                margin-left: 1rem; margin-right: 1rem; margin-top: 1rem;
            };
            .ThumbnailContainer {
                width: 100%;
                align-items: center;
                justify-content: center;
                z-index: 0;
                overflow-y: hidden;
                border-radius: 10px;
                display: flex;
                transition: 200ms;
                .Thumbnail {
                    flex-shrink: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    flex-grow: 0;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .Inner {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 10px;
                        z-index: 1;
                    }
                };
            };
            .Tags {
                margin-left: 12px;
                margin-right: 12px;
                .Chip {padding: 0; margin: 5px; background-color: "white"};
            };
            .Buttons {
                position: relative;
                width: 100%; display: inline-block;
                word-break: keep-all;
                white-space: nowrap;
                .Child {
                    float: left;
                    width: 33.333333%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0;
                    color: white;
                    .BtnTitle {
                        padding-left: 5px;
                        padding-bottom: 6px;
                    }
                    svg {
                        color: white;
                    }
                }
            };
        };
    };
    .Episodes {
        display: flex;
        flex-direction: column;
        .Episode {
            display: flex;
            position: relative;
            padding-right: 20px;
            margin: 0;
            height: 6rem;
            text-align:left;
            justify-content: left;
            align-items: left;
            border-radius: 0;
            .Thumbnail {
                height: 5.2rem; padding: 0.4rem;
            };
            .TextContainer {
                height: 5rem;
                .Title {
                    font-size: 0.8rem;
                };
                .SubTitle {
                    font-size: 0.7rem;
                    color: rgba(51,51,51,0.4);
                };
            };
            .Tag {
                position: absolute;
                bottom: 5px;
                right: 20px;
                font-size: 0.7rem
            }

            &:after {
                content: '';
                width: 90%; margin-left: 5%;
                height: 1px;
                background: lightgray;
                position: absolute;
                bottom: 0px;
              }
        };
    };
    .Recomm {
        .Header {font-size: 1.2rem; padding-left: 10px; padding-right: 10px; padding-top: 30px; padding-bottom: 10px; word-break: keep-all;};
        .Body {
            display: flex;
            padding-right: 25%;
            .GridFeed {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                flex-wrap: nowrap;
                flex-shrink: 0;
            };
        }
    };
    .Review {
        padding-bottom: 40px
    };
};
