$DROPDOWN_CONTAINER_MAX_HEIGHT: (192 + 24 + 2) * 1px;
$DROPDOWN_BUTTON_HEIGHT: 40px;
$ADDITIONAL_BUTTON_HEIGHT: 40px;

.DropDownSelectBox {
    width: 100%;
    height: $DROPDOWN_BUTTON_HEIGHT;

    box-sizing: border-box;

    border-radius: 5px;
    border: 2px solid rgb(236, 237, 243);

    background-color: rgb(255, 255, 255);

    font-family: inherit;

    &:hover {
        border: 2px solid rgba(61, 106, 255, 1);
    }

    &:has([aria-expanded='true']) {
        border: 2px solid rgba(61, 106, 255, 1);
    }

    svg {
        color: rgb(25, 24, 27);
    }

    &.ComparisonRow{
        &:hover {
            border: 2px solid rgb(236, 237, 243);
        }
    
        &:has([aria-expanded='true']) {
            border: 2px solid rgb(236, 237, 243);
        }
    }

    .SelectFormControl {
        width: 100%;

        .SelectBox {
            border: none;

            width: 100%;
            height: 100%;

            color: rgb(25, 24, 27);
            background-color: transparent;

            &.Placeholder {
                color: rgb(189, 189, 194);
            }

            div[role='button'] {
                background-color: transparent;

                font-size: 0.938rem;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.15px;
                word-wrap: break-word;

                padding: 8px 32px 8px 16px;

                &.Mui-disabled{
                    background-color: rgb(221, 221, 221);
                    color: rgb(255, 255, 255);
                    cursor: default;
                }
            }

            &::before,
            &::after {
                all: unset;
            }
        }
    }
}

.PopoverDropDownBackground {
    > div:has(.OptionBox) {
        max-height: $DROPDOWN_CONTAINER_MAX_HEIGHT;

        margin-top: 8px;
        padding: 12px 16px;

        border-radius: 8px;
        border: 1px solid rgb(236, 237, 243);

        box-shadow: 0px 14px 24px rgba(12, 16, 39, 0.12);

        box-sizing: border-box;

        ul {
            max-width: 100%;

            display: flex;
            flex-direction: column;

            gap: 4px;

            padding: 0;

            .OptionBox {
                border: none;

                display: flex;

                color: rgb(60, 70, 81);

                min-height: 44px;

                box-sizing: border-box;
                padding: 12px;

                border-radius: 5px;

                font-size: 0.938rem;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.15px;
                word-wrap: break-word;

                cursor: pointer;

                background-color: transparent;

                &.PlaceholderBox {
                    display: none;
                }

                &:hover {
                    background-color: rgba(60, 70, 81, 0.03);
                }

                &.Mui-selected {
                    background-color: rgb(242, 242, 247);
                }

                span::after {
                    background-color: rgba(60, 70, 81, 0.1);
                }
            }
        }
    }
}