

.NoticeList{

    .ListContainer{
        display: flex;
        justify-content: center;
        padding-top: 75px;

        .ListInner{
            width: 100%;
            max-width: 700px;
            margin: 40px 0;
            
            @media (max-width: 720px) {
                padding: 0 10px;
            }

            .Title{
                font-size: 2rem;
                padding-bottom: 40px;
                border-bottom: 3px solid black;
                font-weight: bold;
                position: relative;

                .MaximumPostSelector{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    position: absolute;
                    right: 0;
                    bottom: 5px;

                    .MuiInput-underline:before{
                        border-bottom: 0px;
                    }
                    
                    .MuiInput-input{
                        font-size: 0.875rem;
                        padding: 0px 24px 0px 0px;
                    }
                }
            }

            .PostTable{
                width: 100%;
                border-collapse: collapse;



                .PostTableBody{
                    .StandardRow{
                        height: 50px;
                        border-bottom: 1.5px solid lightgray;
        
                        .PostType{
                            color: rgb(120,120,120);
                            text-align: center;
                            width: 50px;
                        }
                        
                        .PostTitle{
                            color: rgb(120,120,120);
                            text-align: center;
                            width: calc(100% - 10px - 150px);
                        }
        
                        .PostCreatedTime{
                            color: rgb(120,120,120);
                            text-align: center;
                            width: 100px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }

                    .PostRow{
                        height: 45px;
                        border-bottom: 1.5px solid lightgray;
                        cursor: pointer;

                        .PostType{
                            padding: 0 5px;
                            text-align: center;
                            width: 50px;
                            white-space: nowrap;
                        }
                        
                        .PostTitle div{
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 90%;
                        }
        
                        .PostCreatedTime{
                            padding: 0 5px;
                            color: rgb(120,120,120);
                            text-align: center;
                            width: 100px;
                            white-space: nowrap;
                        }
                    }

                    .PostRow:hover{
                        background-color: rgba(0,176,240,0.1);
                    }

                    .Significant{
                        background-color: rgba(255,255,0,0.2);
                    }
                }
            }

            .ButtonArea{
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;

                Button{
                    margin: 10px 0;
                }
            }

            .ListPagination{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 40px 0;
                
            }

            .SearchArea{
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: flex-end;
                padding: 0 0 20px 10px;
                
                .SearchRow{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: flex-end;
                    background: rgb(255,255,255);
                    border: 1px solid rgb(223,225,229);
    
                    .SearchPost{
                        padding: 0 5px;

                        ::before{
                            border-color: rgba(255,255,255,0);
                        }
                        ::after{
                            border-color: rgba(255,255,255,0);
                        }
                    }
                }

                .SearchIcon{
                    background-color: rgb(0,176,240);
                    width: 34px;
                    height: 34px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0;

                    svg{
                        color: rgb(255,255,255);
                    }
                }
            }
        }
    }

    .InsertImages{
        vertical-align: bottom;
    }
}