.Competition {

    font-size: 0.8rem;

    .CompetitionSubset {
        
        padding: 20px;

        .SubsetHeader {
            margin: 0;    
        }
    }

    .SelectUniversityYearBox{
        display: inline-flex;
        align-items: center;
        gap: 20px;

        margin: 20px;
    }
}