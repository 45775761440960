
$BASICRADIUS: 16px;
$SIGNATURE_COLOR: rgb(0,176,240);
@mixin customflex( $direction: row, $justify: stretch, $align: stretch ){
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

.ApplicationTemplate{
    @include customflex(column, center);
    word-break: keep-all;
    padding-top: 150px;

    .TemplateFooter{
        @include customflex(column, stretch, center);
        padding-bottom: 150px;
        background-image: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 20%, rgba(255,255,255,0) 100%) ,url('https://static.webtoon.today/application/SubmitBackground.png');
        background-repeat: no-repeat;
        background-size: cover;
        
        .EasyToGet{
            @include customflex(column);
            margin-bottom: 50px;
            @media (max-width: 720px) {
                width: calc(100% - 48px);
            }

            .Title{
                @include customflex(row, center);
                margin-bottom: 40px;
                font-size: 2.5rem;
                font-weight: 700;


                @media(max-width: 720px){
                    font-size: 2rem;
                }
            }

            .TextBox{
                text-align: center;
                margin: auto;
                font-size: 1.5rem;

                @media(max-width: 960px){
                    font-size: 1rem;
                }


                .WeightedText{
                    font-weight: bold;
                }
            }
        }

        .SubmitArea{
            @include customflex(row, space-evenly);
            max-width: 842px;
            margin: auto;
            position: relative;
            vertical-align: top;

            @media (max-width: 960px) {
                display: flex;
                flex-direction: column;
                justify-content: stretch;
                align-items: center;
                padding: 0px;
            }
            @media (max-width: 720px) {
                width: 100%;
            }
        
            .InfomationForm{
                vertical-align: top;
                margin: 0px;
                padding: 30px;
                box-sizing: border-box;
                background-color: rgb(255,255,255);
                box-shadow: rgba(200, 200, 200, 60%) 0px 1px 10px 0px;
                    max-width: 700px;

                @media (max-width: 960px){
                    width: calc(100% - 40px);
                }

                .FormUl{
                    list-style-type: none;
                    padding: 0px;
                    display: inline-block;

                    @media (min-width: 720px){
                        max-width: 100%;
                    }

                    .Formli{
                        margin-bottom: 25px;
                        float: left;
                        width: 100%;

                        .EmailReference{
                            font-size: 0.8rem;
                            margin-bottom: 3px;

                            @media(max-width: 720px){
                                font-size: 0.9rem;
                            }
                        }

                        .EmailVerifying{
                            @include customflex();
                            margin-top: 3px;

                            .VerifyingButton{
                                width: 15%;
                                border: 0px;
                                border-radius: 0 4px 4px 0;
                                white-space: nowrap;
                            }
                            
                            .disabled{
                                background-color: #9e9e9e;
                            }
                        }

                        .ServiceSelector{
                            border-radius: 4px;
                            .MuiInputBase-input{
                                padding: 10.5px 14px;
                                border-radius: 0px;
                            }
                        }

                        .VerifiedOtp{
                            @include customflex();

                            .VerifiedOtpButton{
                                width: 15%;
                                border: 0px;
                                border-top-left-radius: 0;
                                border-bottom-left-radius: 0;
                                white-space: nowrap;
                            }
                        }
                            
                        .Tags{
                            width: 100%;
                            font-size: 14px;
                            color: #b0b0b0;
                            box-sizing: border-box
                        }
                    }

                    .Half{
                        width: 49%;
                    }
                    
                    .Qnali{
                        margin-bottom: 25px;
                        float: left;
                        width: 100%;

                        .QnaLabel{
                            margin-bottom: 3px;
                            display: block;
                            font-size: 14px;
                            font-weight: 700;
                            cursor: pointer;
                        }
                        .Qna{
                            width: 100%;
                            height: 140px;
                            height: 140px;
                            padding: 10px 15px;
                            font-size: 14px;
                            color: #b0b0b0;
                            border: 1px solid #bdbdbd;
                            box-sizing: border-box;
                            font-family: "Noto Sans KR", sans-serif;
                            height: 179px;
                            margin: 0px;
                        }
                    }

                }

            }   

            .ClickableArea {
                background-color: rgba(0,0,0,0.2);

                &:hover {
                    background-color: rgba(0,0,0,0.4);
                }
            }

            .References{
                font-size: 0.8rem;
                word-break: break-all;

                @media(max-width: 720px){
                    font-size: 0.9rem;
                }
            }

            
            .RadioGroup{
                padding: 5px 0px 25px 15px;
                width: calc(100% - 30px);

                .Manuscripts{
                    @include customflex(row, space-evenly, flex-start);
                    margin-bottom: 10px;
                    margin-top: 20px;
                    font-size: 1rem;

                    .ManuscriptsFlex{
                        @include customflex(column, center, center);

                        .LabelFlex{
                            @include customflex();

                            .Labels{
                                max-width: 132px;
                                max-height: 20px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                word-break: break-all;
                            }
                        }

                        .ScriptPokets{
                            @include customflex(row, center, center);
                            padding: 5px;
                            height: 122px;
                            width: 122px;

                            @media (max-width: 720px){
                                width: 70px;
                                height: 70px;
                            }
        
                            .SlideThumb{
                                position: relative;
                                flex-shrink: 0;
                                flex-grow: 0;
                                max-width: 100px;
                                min-width: 100px;
                                height: 100px;
                                margin: 5px;
                                color:rgb(233, 233, 233);
                                border: 2px dashed rgba(0, 0, 0, 0.23);

                                @media (max-width: 720px){
                                    min-width: 70px;
                                    max-width: 70px;
                                    height: 70px;
                                    margin: 0px;
                                }

                                span{
                                    color:#b0b0b0;
                                }
                            }

                            .Dragging{
                                background-color: rgb(245,245,245);
                            }
                        }


                        .filenameArea{
                            height: 20px;
                        }
                    }   
                }

                .ChallengeLabel{
                    margin-bottom: 10px;
                }

                .ChallengeWebtoonArea{

                    @include customflex(row, center);
                    width: 100%;
                    margin-bottom: 15px;
                    max-height: 40px;

                    .ChallengeAddressField{
                        width: 70%;
                    }

                    .ColoredButton{
                        width: 15%;
                        white-space: nowrap;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        border:0;
                    }
                }

                .ExtraTitleInfomation{
                    margin-left: -5px;
                }


            }

            .ChallengeInfo{
                width: 100%;
                
                .ExtractInformation{
                    position: relative;
                    margin: 20px;
                    padding: 40px;
                    @include customflex(column, center, center);
                    background-color: rgba(0,0,0,0);

                    .OutText{
                        background: rgb(247,247,247);
                        position: absolute;
                        top: -14px;
                        left: 20px;
                        padding: 4px;
                        font-size: 0.8rem;
                    }

                    .ImgArea{
                        padding: 20px ;
                        @include customflex(row, center);

                        .Thumbnail{
                            border: 1px solid rgb(255, 255, 255);
                            border-radius: $BASICRADIUS;
                        }
                    }
                
                    .WebtoonInfo{
                        margin-bottom: 20px;

                        .Title{
                            font-size: 2rem;
                            font-weight: bold;
                            text-align: center;
                            padding-bottom: 20px;

                        }
                        .Description{
                            background-color: rgb(255, 255, 255);
                            border: 1px rgba(0, 0, 0, 0.12) solid;
                            border-radius: $BASICRADIUS;
                            padding: 20px;
                        }
                    }

                    .CorrespondText{
                        font-size: 0.9rem;
                        color: $SIGNATURE_COLOR;
                    }
                }
            }
            
            @media (min-width: 721px) {
                .ChallengeInfo {
                    width: 600px;
                }                    
            }

            .TitleUl{
                .Titleli{
                    margin-bottom: 25px;

                    .TestRequests{
                        @include customflex();
                        padding: 0px;
                        margin: 5px 0 0 0;

                        .RequestBox{
                            display: flex;
                            align-items: center;
                            font-size: 0.9rem;
                            margin: 2px 8% 2px 0;

                            span{
                                margin-right: 2px;
                            }
                            .Checker{
                                padding: 0px;
                            }
                        }

                        .RequestBox:last-child{
                            margin-right: 0;
                        }
                    }

                    .ExampleReport{
                        font-size: 0.8rem;
                        color: $SIGNATURE_COLOR;
                        cursor: pointer;
                    }
                }
            }

            .AdsCutUl{
                .AdsCutli{
                    margin-bottom: 25px;

                    .Labels{
                        margin-bottom: 10px;
                    }

                    .AdsCutRequest{
                        @include customflex(row, stretch, center);
                        margin-top: 10px;
                        font-size: 0.9rem;

                        .AdsCutRequestCheckbox{
                            padding: 0px;
                            margin-right: 3px;
                        }
                    }
                }
            }


            .ArrowButton {
                float:left;
                margin-top:2px;

                svg {
                    font-size: 1.2rem;
                }

                &.Open {
                    svg {
                        transform: rotate(90deg);
                    }
                }
            }

            .Appendix {
                max-height: 1px;
                overflow-y: hidden;
                border: 0.5px lightgray solid;
                border-radius: 4px;
                padding: 20px 20px 0px 20px;
                
                transition: 200ms;
                margin-bottom: 10px;

                &.Open {
                    max-height: 999px;
                }

                &.Closed {
                    max-height: 1px;
                    padding: 0px 20px 0px 20px;
                    border: none;
                }
                
                .FormUl{
                    list-style-type: none;
                    padding: 0px; width: 100%;
                    display: inline-block;

                    @media (min-width: 720px){
                        width: 600px;
                    }

                    .Formli{
                        margin-bottom: 25px;
                        float: left;
                        width: 100%;
                    }

                    .HasExperienceRow{
                        @include customflex(row, stretch, center);
                        font-size: 0.9rem;
                        width: 100%;
                        margin-bottom: 25px;
                        
                        .HasExperienceCheckbox{
                            padding: 0;
                        }
                    }
                    
                    .TitleExtraInfomation{
                        @include customflex(row, space-between, flex-end);
                        float: left;
                        width: 100%;
                        margin-bottom: 15px;
                        font-size: 1rem;
                        font-weight: bold;

                        .TargetSelectArea{
                            @include customflex(row, stretch, flex-end);

                            .TargetAge, .TargetGender{
                                min-width: 80px;
                                
                                div{
                                    padding-bottom: 3px;
                                    font-size: 0.9rem;
                                }
                            }

                            .TargetGender{
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
            

            .AgreementsArea{
                @include customflex(row, center, stretch);

                .FlexBox{
                    @include customflex(row, stretch, center);
                    font-size: 14px;
                    font-weight: 500;
                    color: #9e9e9e;

                    .CheckBox{
                        margin-right: 5px;
                    }

                    .CheckLabels{
                        color: rgb(0,0,0);
                        font-size: 0.9rem;
                        word-break: keep-all;
                        padding: 2px 0px;
                    }

                    .PopOver{
                        @include customflex(row, center, center);
                        border-radius: 15px;
                        background-color: $SIGNATURE_COLOR;
                        height: 15px;
                        width: 15px;
                        color: $SIGNATURE_COLOR;
                        cursor: pointer;
                    }
                }

                .ConsentArea{
                
                    .CheckRow{
                        @include customflex(row, stretch, center);
                        font-size: 0.8rem;

                        .CheckPoints{
                            padding: 0px;
                            margin-right: 3px;
                        }

                        .Plane{
                            pointer-events: none;
                        }
                    }
                }
            }

            .PopupArea{
                @include customflex(row, center);
                margin-top: 30px;
                font-size: 1rem;

                .SubmitButton{
                    padding: 12px 40px;
                    font-size: 16px;
                    font-weight: 500;
                    border: 0;
                }

                .disabled{
                    background-color: #9e9e9e;
                }

            }
        }

        .DialogArea{
            @include customflex(column, center, center);
            width: 200px;
            margin: auto;
            margin: 20px 0px;
            cursor: pointer;
            font-size: 0.9rem;

            .DialogText{
                margin-top: 10px;
                color: $SIGNATURE_COLOR;
                border-bottom: 1px solid $SIGNATURE_COLOR;
            }
        }
    }
    
    .Labels{
        margin-bottom: 5px;
        display: block;
        font-size: 1rem;
        font-weight: bold;
    }

    .redAsterisk{
        color: #bf0000;
    }
                
    .PopupPaper{
        @include customflex();
        padding: 20px;
    }

    .PopOverTexts{
        padding: 4px;
        color: $SIGNATURE_COLOR;
        background-color: rgba(80, 173, 234, 0.2);
        font-size: 12px;
    }

    .SignatureText{
        color: $SIGNATURE_COLOR;
    }


    .WeightedText{
        font-weight: 700;
    }

    .TextCenter{
        text-align: center;
    }

    .Dialog{
        .DialogTitle{
            padding: 20px 24px 16px 24px;
            font-size: 1.2rem;
        }

        .DialogContent{
            padding: 8px 24px 8px 24px;
        }

        .DialogActions{
            @include customflex(row, flex-end);
            padding: 16px 24px 16px 24px;

            .PopupButton{
                padding: 4px 6px;
                font-size: 1rem;
                font-weight: 500;
                border: 0;
            }
        }
    }


}
