
.EachProgressInner{
    
    .EachProgressContainer{
        max-width: "1000px";
        margin: auto;
        padding: 50px 0;

        .WebtoonTitle{
            font-size: 2rem;
            text-align: center;
        }
    }
}