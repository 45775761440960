.NoticeSection {
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-family: Pretendard;

    .PostItemContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 40px 0;

        .PostItem {
            width: 100%;
            display: flex;
            justify-content: space-between;

            gap: 12px;

            .ItemText {                
                word-break: keep-all;
                word-wrap: break-word;

                color: rgba(121, 120, 130, 1);
                font-style: normal;
                font-weight: 600;
                font-size: 1.25rem;
                line-height: 32px; /* 160% */
                letter-spacing: 0.3px;
                
                @media (max-width:1024px) {
                    font-size: 1rem;
                    line-height: 24px;
                    letter-spacing: 0.2px;    
                }
            }
        }
    }

    .LinkButtonWrapper {
        display: flex;
        justify-content: center;
        padding: 24px 40px 0 40px;
        font-family: Pretendard;
        
        .LinkButton {
            padding: 12px;
            border-radius: 8px;
            background-color: rgba(60, 70, 81, 0.03);

            &:hover{
                background-color: rgba(0, 0, 0, 0.03);
            }
    
            .TextWrapper {
                display: inline-flex;
                gap: 4px;
    
                .ButtonText {
                    color: rgba(60, 70, 81, 1);
                    font-family: Pretendard;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; /* 150% */
                    letter-spacing: 0.16px;
                }
    
                .ArrowRight {
                    width: 20px;
                    height: 20px;
                    margin: auto;
                }
            }          
        }
    }
}