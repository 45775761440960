.ImageHolder {
    position: relative;

    .ImageHolderContainer {
        all: initial;
        user-select: none;
        img {
            user-select: none;
        }
    }
    .Insert {
        position: absolute;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        opacity: 0;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.Top {
            top: 0;
        }
        &.Bottom {
            bottom: 0;
        }


        &:hover {
            z-index: 1;
            height: 40px;
            opacity: 0.5;
            background-color: rgba(255,255,255,1);

            &.Top {
                top: -20px;
            }
            &.Bottom {
                bottom: -20px;
            }
        
        }
    }
}