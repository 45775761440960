.ProgressArea{

    .ProgressInner{
        padding: 0 20px;
        max-width: 1000px;
        margin: auto;

        .ProgressContainer{
            padding: 50px 0;

            .StandardRow{

                .Webtoon{
                    padding: 10px 10px 50px 10px;
                    width: 25%;
                    font-size: 2rem;
                    text-align: center;
                    border-bottom: 2px solid rgb(200,200,200);
                    text-align: center;
                }

                .EmptyCell{
                    width: 5%;
                }

                .Progress{
                    padding: 10px 10px 50px 10px;
                    width: 65%;
                    font-size: 2rem;
                    text-align: center;
                    border-bottom: 2px solid rgb(200,200,200);
                }
            }

            .ProgressRow{

                .Webtoon{
                    border-bottom: 1px solid lightgray;
                    cursor: pointer;
                    padding: 10px 20px;
                }
                
                .Progress{
                    padding: 15px 20px;
                    border-bottom: 1px solid lightgray;

                    .ProgressTexts{
                        display: flex;
                        flex-direction: column;

                        .CommentRow{
                            width: calc(100% - 20px);
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .NoteCell{
                                position: relative;
                                width: calc(80% - 20px);

                                .PresentText{
                                    font-size: 1rem;
                                }
                                .PreviousText{
                                    color: lightgray;
                                    font-size: 0.9rem;
                                
                                }
                                .AddNoteButton{
                                    width: 21px;
                                    height: 21px;
                                    position: absolute;
                                    left: -25px;
                                    padding: 0px;
                                }
                                .Top{
                                    top: 1px;
                                }
                                .Bottom{
                                    bottom: 5px;
                                }
                            }
                            
                            .TimeCell{
                                display: flex;
                                justify-content: center;
                                width: calc(20% - 20px);

                                .Time{
                                    padding: 3px 8px;
                                    background-color: lightgray;
                                    font-size: 0.7rem;
                                    border-radius: 10px;
                                }
                            }
                        }
                    }
                }
            }

        }
    }

}