.GraphHome {
    inherits: no;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .Body {
        max-width: 690px;
        margin-left: auto; margin-right: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;

        @media (max-width: 720px){
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &.Wide {
            max-width: 1380px;

            .ImageViewVert {
                .MainImage {
                    width: 50%;
                }
                .SubImage {
                    width: 50%;
                }    
            }
        }

        .PopupButtons{
            font-size: 1rem;
            padding: 3px;
            width: 18px;
            height: 18px;
            border: 1px solid #00b0f0;
        }

        img {
            width: 100%;
            vertical-align: bottom;
            user-select: none;
        }
        padding-bottom: 60px;

        .ImageViewVert {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;

        }

    }
    .ReviewInShort {
        margin: 1rem;
    }
    .Chart {
        height: 800px;
        width: 150px;
        padding: 0.5rem;
        background-color: rgba(255,255,255,0.6);
        transition: 200ms;
        border: 0.5px lightgray solid;
        position: relative;

        &.Hide {
            right: calc(-150px - 1rem);
        }

        .Churn {
            position: absolute; width: 90%; height: 100%;
        }

        .AvgStay {
            position: absolute; width: 90%; height: 100%;
            .Bar {
                position: absolute;
                background-color: rgba(0,0,0,0.6);
                height: 0.5rem;
            }
        }
        
    }

    .Description{
        white-space: pre-line;
        padding-left: 20px;
        @media (max-width: 720px){
            margin-top: 20px;
        }

        .TitleText{
            font-size: 1.2rem;
            font-weight: bold;
            padding-bottom: 10px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .BarIcon{
                margin-left: 5px;
                color: rgba(0,0,0,0.6);
            }
            .LineIcon{
                margin-left: 5px;
                color: rgba(244, 20, 20, 0.6);
            }
        }
    }

    .NextEpButton {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        position: fixed;
        bottom: 65px;
        right: 10px;
    }
    
    .Score {
        white-space: nowrap;
        position: fixed;
        display: flex;
        flex-direction: column;
        background-color: rgba(255,255,255,0.6);
        right: 10px;
        bottom: 10px;

        .DualSnapshotSelectContianer {
            display: flex;
            flex-direction: row;
            align-items: center;

            div, select {flex-grow: 1;}
        }
    }

    &.EditMode {
        .Chart {
            border: 1px lightgray solid;
        }
    }
}