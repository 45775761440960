.SurveyEditor {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;

    .BigColumn {
        width: 40%;
        max-height: calc(100vh - 100px);
        overflow-y: auto;
        padding-bottom: 50px;

        .QuestionCell {
            margin: 10px;
            padding: 10px;
            display: flex;
            flex-direction: column;

            .MuiFormControlLabel-root {
                margin-right: 0;
            }

            .Row {
                display: flex;
                flex-direction: row;
                margin-bottom: 5px;

                div {
                    margin-right: 5px;
                }

                .Label {
                    white-space: nowrap;
                    vertical-align: middle;
                    
                }
            }

            .Col {
                display: flex;
                flex-direction: column;

            }

            .Depth { margin-left: 20px }

        }
    }
    .AddAQuestion {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow-y: hidden;
        height: 20px;
        margin: 0 10px;

        transition: 100ms;

        cursor: pointer;

        border: 0.5px rgb(155,155,155) dotted;
        border-radius: 2px;

        &:hover {
            height: 60px;
            background-color: rgb(233,233,233);
            border-radius: 10px;
            border: none;

            svg {
                display: block;
            }
        }

        svg {
            display: none;
        }
    }
}