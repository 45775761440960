$SIGNATURE_COLOR: rgb(0, 176, 240);

.Scholarship {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(233,233,233,1);

    .Img {
        margin-top: 10px;
        width: calc(100% - 5px);
        max-width: 500px;
        box-shadow: 0px 5px 5px 2px rgba(0,0,0,0.1);
    }

    .Btn {
        margin: 20px;
        width: calc(100% - 5px);
        max-width: 500px;
        background-color: $SIGNATURE_COLOR;
        color: white;
    }
}