$FONT_SIZE_H1: 3rem;
$FONT_SIZE_H2: 2rem;
$FONT_SIZE_H3: 1.5rem;
$FONT_SIZE_H4: 1rem;
$FONT_SIZE_H5: 0.7rem;

$FONT_WEIGHT_H1: bolder;
$FONT_WEIGHT_H2: bold;
$FONT_WEIGHT_H3: initial;

