.Competition {

    font-size: 0.8rem;

    .CompetitionSubset {
        
        padding: 20px;

        .SubsetHeader {
            margin: 0;    
        }
    }
}