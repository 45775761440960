.InsightSearch{
    max-width: 1024px;
    margin: 0 auto;
    padding: 40px 0;

    .TitleForm{
        display: flex;
        flex-direction: column;
        gap: 12px;

        input[type="number"]::-webkit-outer-spin-button,
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}