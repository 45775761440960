
.SummaryDiagnosisArea{
    width: 100%;
    max-width: 1024px;
    box-sizing: border-box;
    margin: 0 auto 40px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    background-color: rgb(255,255,255);

    @container                       (width > 1024px) { max-width: 1024px; }
    @container (width <= 1024px) and (width >  700px) { max-width:  630px; }
    @container (width <=  700px)                      { max-width:  424px; }

    .DateText{
        color: rgb(196, 196, 196);
        font-size: 0.8125rem;
        font-weight: 500;
        line-height: 20px;
    }
    .DiagnosisResultHeader{
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-content: center;

        font-style: normal;
        font-weight: 600;
        line-height: 20px;

        .TitleText{
            color: rgba(25, 24, 27, 1);
            font-size: 1.25rem;
        }
    }
    .DiagnosisBody{
        display: flex;
        flex-direction: column;
        gap: 36px;

        .DiagnosisBlocks{
            display: flex;
            flex-direction: column;
            gap: 20px;

            .BlockHeader{
                white-space: pre-line;

                .Title{
                    color: rgba(25, 24, 27, 1);
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 32px; /* 200% */
                }
                .SubTitle{
                    color: rgba(25, 24, 27, 1);
                    font-size: 0.8125rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 153.846% */
                }
            }
            
            .EachResultContainer{
                display: flex;
                flex-direction: column;
                gap: 24px;
                width: 100%;
                
                .InfoMessageBox{
                    display: flex;
                    align-items: center;
                    padding: 12px 12px 12px 8px;
                    gap: 16px;
                    border-radius: 4px;
                    background-color: rgba(255, 254, 234, 1);
                    word-break: keep-all;
    
                    >span {
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px; /* 150% */
                        letter-spacing: 0.16px;

                        @container (width <= 700px) {
                            font-size: 0.875rem;
                        }
                    }
                }
    
                .DiagnosisSummaryContainer{
                    display: flex;
                    gap: 36px;
                    
                    @container (width <= 1024px) {
                        flex-direction: column;                        
                    }
    
                    >div{
                        flex: 1;
                    }
    
                    .ScatteredPlot{
                        border-radius: 4px;
                        display: flex;
    
                        svg{
                            border: 1px solid rgba(236, 237, 243, 1);
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
    }
}