html { overscroll-behavior: none }
body { overscroll-behavior: none }

.Home {
    @media (max-width: 40em) {
        font-size: 0.6em;
        

        .TextAndSlideShow {
            flex-direction: column;
            .Text {
                position: relative;
                width: 300px;
            }

            .SlideShow {
                position: relative;
                width: 300px; height: 300px;
                margin-left: auto;
                margin-right: auto;;
            }
        }

        ul span:last-child {
            display: block;
        }
    }
    @media (min-width: 40em) {
        
        .TextAndSlideShow {
            flex-direction: row;
            .Text {
                max-width: 500px;
                min-width: calc(50% - 60px);
                width: calc(100% - 360px);
            }

            .SlideShow {
                width: 300px; height: 300px;
                max-width: 50%;
                margin-top: auto;
                margin-bottom: auto;;
            }

            .Left {
                left: 30px;
            }

            .Right {
                right: 30px;
            }
        }
    }

    overscroll-behavior: none;
    position: relative;
    overflow: hidden;
    width: 100%;

    .BackgroundVid {
        position: fixed;
        left: 0; top: 0; width: 100%; height: 100%;

        video {
            opacity: 0.6;
            filter: grayscale(1);
            object-fit: cover;
        }
    }

    .GridBG {
        position: fixed;
        left: 0; top: 0; width: 100%; height: 100%;
        background-image: url('https://static.webtoon.today/ddah/grid.png');
        background-repeat: repeat;
        background-size: 20%;
    }

    .Container {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        
        .TopLeft {
            position: absolute;
            top: 0;
            left: 0;
        }

        .BottomRight {
            bottom: 0;
            right: 0;
        }

        .Text {
            z-index: 1;
            position: absolute;
            font-size: 1.5em;
            color: white;
            word-break: keep-all;
            text-align: center;

            opacity: 0;
            transition: 1000ms;

            &.Subheader {
                font-size: 2em;
            }
            
        }

        .WithLogo {
            z-index: 1;
            line-height: 4em;
            font-size: 2em;
            img {
                vertical-align: middle;
                margin-bottom: 10px;
            }
        }
        
        .TextAndSlideShow {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .Text {
                position: relative;
            }
        }

        .ProfileContainer {
            width: calc(100% - 80px);
            height: calc(100% - 40px);
            padding: 20px;
            margin-left: 20px; margin-right: 20px;
            border-radius: 20px;
            background-color: white;

            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            font-size: 1.2rem;

            img.Profile {
                width: 200px;
                height: 200px;
                border-radius: 100px;
                object-fit: cover;
            }

            ul {
                width: calc(100% - 40px);
                li {
                    max-width: 100%;

                    :last-child {
                        white-space: nowrap;
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        img {
            z-index: 1;
        }

        .Background {
            position: absolute;
            left: 0; top: 0; width: 100%; height: 100%;

            &.White {
                background-color: white;
            }

            &.Lightgray {
                background-color: rgba(111,111,111,1);
            }

            &.Lightblue {
                background-color: rgba(173, 216, 230,0.8);
            }

            &.Red {
                background-color: rgb(155, 98, 98);
            }

        }

        img.BigLogo{
            width: 300px;
            max-width: 60%;
        }

        &.TextVisible {
            .Text {
                opacity: 1;
            }
        }

        .BottomButtons {
            position: absolute;
            width: 100%;
            bottom: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            .FlatButton {
                background-color: white;
                width: 200px;
                max-width: 25%;
            }
        }
    }

    .Bottom{
        position: fixed;
        padding-left: 20px; padding-right: 20px; padding-bottom: 20px; padding-top:180px;
        left:0; width: calc(100% - 40px);
        bottom: -100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        transition: 200ms;
        background: linear-gradient(0deg, rgba(0,0,0,0.4), transparent);
        color: white;

        .CompanyInfo {

            a {
                color: inherit;
                text-decoration: none;

                &:visited {
                    color: inherit;
                    text-decoration: none;
                }
            }
        }

        .Contact {
            color: white;
        }
    }

    .TopMenu {
        position: fixed;
        top: 0;
        right: 0;
        color: white;
        padding: 10px;

        .Button {
            color: white;
            text-shadow: 0 0 2px black;
            width: 100px;
        }
    }

    .Controller {
        position: fixed;
        bottom: 80px;
        right: 12px;
        color: white;
        padding: 10px;
        display: flex;
        flex-direction: column;

        .Button {
            color: white;
            border: 1px white solid;
            text-shadow: 0 0 2px black;
            width: 10px;
            height: 10px;
            margin: 5px;

            &.Mui-disabled {
                color: lightgray;
                border: 1px lightgray solid;
            }
        }
    }

    &.At_Last {
        .Bottom {
            bottom: 0px;
        }
    }

    .SlideShow {
        position: relative;
        overflow-x: hidden;

        @keyframes img_slide {
            0% {
                left: 0;
            }

            2% {
                left: 0;
            }

            16.6% {
                left: -100%;
            }

            33.2% {
                left: -100%;
            }

            50% {
                left: -100%;
                opacity: 0;
            }

            66.6% {
                left: 100%;
                opacity: 0;
            }

            83.2% {
                left: 100%;
                opacity: 1;
            }

            85.2% {
                left: 100%;
            }

            100% {
                left: 0;
                z-index: 2;
            }

        }

        img {
            all: initial;

            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: contain;
            background-color: white;
            &:nth-child(1) {
                animation: img_slide 12s ease-in-out Infinite -2s;
            }
            &:nth-child(2) {
                animation: img_slide 12s ease-in-out Infinite -4s;
            }
            &:nth-child(3) {
                animation: img_slide 12s ease-in-out Infinite -6s;
            }
            &:nth-child(4) {
                animation: img_slide 12s ease-in-out Infinite -8s;
            }
            &:nth-child(5) {
                animation: img_slide 12s ease-in-out Infinite -10s;
            }

            &:nth-child(6) {
                animation: img_slide 12s ease-in-out Infinite 0s;
            }
        }
    }

    .Glass {
        opacity: 0.8;
        backdrop-filter: blur(5px);
    }
    
    .GradientSkyblueToSkygreen {
        background: linear-gradient(
            to bottom right,
            rgb(79, 153, 202),
            rgb(79, 202, 165),
        );
    }

    .GradientSkyredToSkyorange {
        background: linear-gradient(
            to top right,
            rgb(202, 79, 79),
            rgb(202, 145, 79),
        );
    }
}