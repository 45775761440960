
.ItemDetailInfo {
    display: flex;
    flex-direction: row;
    flex-grow:1;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 2px;
    margin-right: 2px;
    height: 60px;
    .MetaRow {
        display: flex;
        flex-direction: column;
        flex-grow:1;
        justify-content: space-evenly;
        align-items: center;
        .HeaderCell {
            width: 50px;
            height: 20px;
            font-size: 0.6rem;
            padding-left: 5px; padding-right: 5px;
            white-space: nowrap;
            text-align: center;
            line-height: 20px;
        };
        .RecommHeaderCell {
            width: 50px;
            height: 20px;
            font-size: 0.6rem;
            padding-left: 5px; padding-right: 5px;
            white-space: nowrap;
            text-align: center;
            line-height: 20px;
        };
        .BodyCell {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 40px;
            font-size: 1rem;
            padding-left: 5px;
            padding-right: 5px;
            text-align: center;
            .Inner {max-width: 100%; max-height: 100%};
        }
    };
};