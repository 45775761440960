.ContentsHome {
    inherits: no;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .Body {
        max-width: 690px;
        margin-left: auto; margin-right: auto;

        &.Wide {
            max-width: 1380px;

            .ImageViewVert {
                .MainImage {
                    width: 50%;
                }
                .SubImage {
                    width: 50%;
                }    
            }
        }

        img {
            width: 100%;
            vertical-align: bottom;
            user-select: none;
        }
        padding-bottom: 60px;

        .ImageViewVert {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;

        }

    }
    .ReviewInShort {
        margin: 1rem;
    }
    .Chart {
        position: fixed;
        top: 4rem; bottom: 150px; right: 10px;
        padding: 0.5rem;
        width: 150px;
        background-color: rgba(255,255,255,0.6);
        transition: 200ms;
        border: 0.5px lightgray solid;

        &.Hide {
            right: calc(-150px - 1rem);
        }

        .Churn {
            position: absolute; top: 0; left: 0; width: 90%; height: 100%;
            
        }

        .AvgStay {
            position: absolute; top: 0; left: 0; width: 90%; height: 100%;
            .Bar {
                position: absolute;
                background-color: rgba(0,0,0,0.6);
                height: 0.5rem;
            }
        }
        
    }

    .NextEpButton {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        position: fixed;
        bottom: 65px;
        right: 10px;
    }
    
    .Score {
        white-space: nowrap;
        position: fixed;
        display: flex;
        flex-direction: column;
        background-color: rgba(255,255,255,0.6);
        right: 10px;
        bottom: 10px;

        .DualSnapshotSelectContianer {
            display: flex;
            flex-direction: row;
            align-items: center;

            div, select {flex-grow: 1;}
        }
    }

    &.EditMode {
        .Chart {
            border: 1px lightgray solid;
        }
    }
}