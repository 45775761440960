
.FunnelDiagnosisArea{
    width: 100%;
    max-width: 1024px;
    padding: 5px 0px;
    box-sizing: border-box;
    margin: auto;

    @container (width <=  700px) {
        padding: 5px 10px;
        width: 100%;
    }

    @container                       (width > 1024px) { &.FullSize { display: block;} &.HalfSize {display: none;} &.OneSize {display: none;} }
    @container (width <= 1024px) and (width >  700px) { &.FullSize { display: none;} &.HalfSize {display: block;} &.OneSize {display: none;} }
    @container (width <=  700px)                      { &.FullSize { display: none;} &.HalfSize {display: none;} &.OneSize {display: block;} }

    &.HalfSize {
        max-width: 636px;
    }

    &.OneSize {
        max-width: 424px;
    }

    .PrevNext {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .FunnelDiagnosisInner{
        display: flex;
        gap: 16px;

        .TableCol{
            flex: 1;
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            gap: 16px;
            width: 100%;

            .TableHeaderCell{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                border-radius: 12px;
                background-color: rgba(242, 242, 247, 1);
                flex-shrink: 0;

                text-align: center;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 142.857% */
                letter-spacing: 0.14px;
                color: rgba(60,70,81,1);

                width: 100%;
                max-width: 196px;
            
                &.Hidden{
                    max-width: 176px;
                    opacity: 0;
                    background-color: rgba(255,255,255,0);
                }
            }

            .KeyLabelBox{
                color: rgba(127, 144, 164, 1);
                font-size: 0.8125rem;
                font-style: normal;
                font-weight: 500;
                line-height: 20px; /* 153.846% */
                letter-spacing: 0.13px;
            }
            .ValueLabelBox{
                text-align: right;

                .ValueLabel{
                    color: rgba(127, 144, 164, 1);
                    font-size: 0.8125rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 153.846% */
                    letter-spacing: 0.13px;
                }
                .Value{
                    color: rgba(60, 70, 81, 1);
                    font-size: 0.8125rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px; /* 153.846% */
                    letter-spacing: 0.13px;
                }
            }

            .TableBodyCells{
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 100%;
                box-sizing: border-box;

                .TableRowHeaderCell, .TableBodyCell{
                    display: flex;
                    flex-direction: column;
                    flex-shrink: 0;
                    border-radius: 12px;
                    border: 1px solid rgba(236, 237, 243,1);
                    height: 170px;
                    box-sizing: border-box;
                }
    
                .TableRowHeaderCell{
                    gap: 20px;
                    background-color: rgba(242, 242, 247, 1);
                    justify-content: space-between;
                    align-items: center;
                    padding: 12px 20px;

                    @container (width <= 700px) {
                        padding: 6px 10px;
                    }
    
                    .HeaderCellTitle{
                        width: 100%;
                        text-align: start;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 20px; /* 142.857% */
                        letter-spacing: 0.14px;
                        color: rgba(60,70,81,1);
                    }
                    .HeaderBodyCellBox{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                    }
                }
                .TableBodyCell{
                    background-color: rgba(249, 251, 255, 1);
                    max-height: 200px;

                    .InnerCell{
                        padding: 12px 20px;
                        display: flex;
                        flex-direction: column;
                        gap: 6px;
                        
                        @container (width <= 700px) {
                            padding: 6px 10px;
                        }
                        
                        &:not(:last-child){
                            border-bottom: 1px solid rgba(236, 237, 243, 1);
                        }

                        .ContentPart{
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                        }
                    }
                }
            }
        }

        .TableCol.Selected{
            .TableHeaderCell{
                background-color: rgba(61, 106, 255, 1);
                color: rgba(255,255,255,1);
            }
            .TableBodyCells{
                .TableRowHeaderCell, .TableBodyCell{
                    border: 2px solid rgba(61, 106, 255, 1);
                }
            }
        }
    }
}