$BASIC_SPACE: 8px;
$SIGNATURE_COLOR: rgb(0, 176, 240);



.MotherBoardSubRoot{
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;



    .BoardOverlay{
        z-index: 4;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        background: radial-gradient(circle, rgba(217, 245, 255, 0.1), rgba(217, 245, 255, 1) 60%, rgb(217, 245, 255) 65%);
    
        @media(max-width: 961px){
            background: radial-gradient(circle, rgba(217, 245, 255, 0.1), rgba(217, 245, 255, 1) 65%, rgb(217, 245, 255) 65%);
        }

    }

    .BoardImgMotionContainer {

        position: absolute;
        width: 100%;
        height: 80%;
        overflow-x: hidden;

        .BoardImgContainer {
            position: relative;
            height: 100%;

            .BoardImgRight{
                object-fit: contain;
                top: 0px;
                height: 100%;
                position: absolute;

                box-shadow: 2px 2px 0 rgb(255, 255, 255),
                            3px 3px 4px rgba(0, 0, 0,.9),
                            6px 6px 12px 4px rgba(0,0,0,.25),
                            0 0 14px 4px rgba(0,0,0,.15);
                border: rgb(255, 255, 255) 10px solid;
                border-radius: 3px;
                background-color: white;
                transform-origin: right;
                transform: perspective(1250px) rotateY(-30deg);
                
                right: 0%;
            }

            
            .BoardImgLeft{
                object-fit: contain;
                top: 0px;
                height: 100%;
                position: absolute;

                box-shadow: -11px 5px 15px 5px rgba(0,0,0,0.32);
                border: rgb(255, 255, 255) 10px solid;
                border-radius: 3px;
                background-color: white;
                transform-origin: left;
                transform: perspective(1250px) rotateY(30deg);
                
                left: 0;
                
            }
        }

    }
    .Content{
        z-index: 5;
        text-align: left;
        position: relative;
        padding: $BASIC_SPACE * 5;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;

        @media(max-width: 720px){
            padding: $BASIC_SPACE * 5;
        }
        
        background-color: rgba(239, 250, 254, 0.25);
        backdrop-filter: blur(1px);
        border-radius: 12px;
        
    
        .HeaderContent{

            .TitleLine{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 3.5rem;
                white-space: nowrap;
                font-weight: bold;
                
                @media(max-width: 720px){
                    font-size: 2rem;
                }
            }

            .ImageLine{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                max-width: 100%;
                white-space: nowrap;

                .Logo{
                    width: 70%;
                    display: block;
                }
            }

            .Title{
                color: $SIGNATURE_COLOR;
                font-weight: bold;
                word-break: keep-all;
            }
        }

        .Context{
            padding-top: $BASIC_SPACE * 5;
            padding-bottom: $BASIC_SPACE * 5;
        }


        .Link{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: $BASIC_SPACE * 5;
            color: rgb(0,176,85);
            font-weight: bold;

        }

    }

}