$TableBorderColor: white;

.ViralAnalyticsArea{

    .ControlContainer{
        display: flex;
        flex-direction: column;

        .ViralRequestBox{
            gap: 10px;

            display: flex;
            align-items: center;
            padding: 10px;
    
            .RequestTitle{
                width: 100px;
    
            }
            .RequestInputBox{
                display: flex;
                gap: 5px;
    
                .RequestTitleButtonBox{
                    .RequestTitleButton{
                        border: 1px solid black;
                    }
                }
            }
        }
    
        .ViralSearchBox{
            padding: 10px;

            display: flex;
            gap: 10px;
            align-items: center;
    
            .SearchTitle{
                width: 100px;
            }
            .SearchInputBox{
                display: flex;
                gap: 5px;
            }
        }
    
    }
    
    .ViralSearchResultWrapper{
        padding-bottom: 50px;
        border-bottom: 1px solid black;

        .ViralDataTableBox {
            width: 100%;
            padding-bottom: 15px;
            margin: auto;
            
            font-family: Pretendard;

            display: inline-flex;
            padding: 30px;
            box-sizing: border-box;

            .ViralMainTable {
                display: flex;
                flex-direction: column;
                max-width: 1440px;
                width: 100%;

                .HeadRow, .Row{
                    display: flex;
                    width: 100%;

                    .HeadCell, .Cell{
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        flex-shrink: 0;

                        border-left: 1px solid $TableBorderColor;
                        border-top: 1px solid $TableBorderColor;
                        &:last-child{
                            border-right: 1px solid $TableBorderColor;
                        }

                        &:nth-child(n) {
                            flex: 1;
                            width: 120px;
                        }
                        
                    }
                    &:last-child{
                        .HeadCell, .Cell{
                            border-bottom: 1px solid $TableBorderColor;
                        }
                    }

                }

                .HeadRow{
                    display: flex;

                    color: white;
                    font-size: 1rem;
                    font-weight: 700;
                    background-color: rgb(39, 174, 96);

                    .HeadCell{
                        display: flex;
                        justify-content: space-between;;
                        padding: 6px 12px;
                    }
                }

                .Row{
                    background-color: rgb(246,246,246);
                    font-size: 0.875rem;

                    .Cell{
                        padding: 4px 12px;
                    }
                }

                .RawData{
                    max-height: 600px;
                    overflow: auto;

                    &::-webkit-scrollbar {
                        all: initial;
                        height: .8rem;
                        width: .8rem;
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        background-color: rgb(150,150,150);
                        border-radius: 1rem;
                    }

                    font-size: 0.8rem;
                    background-color: white;

                    .DataCell {
                        width: 100%;

                        display : flex;
                        flex-direction: column;
                        border: 1px solid $TableBorderColor;
                        border-bottom: none;
                        
                        .CellDataTable{
                            padding: 5px 10px;
                            box-sizing: border-box;
                            border-bottom: 1px dashed black;

                            .CellDataInnerTable{
                                padding: 0 5px;
                                box-sizing: border-box;
                                flex: 1;

                                .InnerRow{
                                    display: flex;
                                    border-bottom: 1px solid transparent;
                                    
                                    .InnerCell{
                                        width: 130px;
                                        padding: 2px 6px;
                                        flex-shrink: 0;
                                        &:not(.Value){
                                            background-color: rgba(0, 176, 240, 0.1);
                                        }
                                    }
                                    .Value.InnerCell{
                                        flex: 1 0 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ViralRequestResultWrapper{
        padding: 10px;
        box-sizing: border-box;

        .ViralRequestResultTitle{
            font-size: 1.25rem;
            font-weight: 700;
        }
    
        .ViralRequestTable{
            padding-top: 20px;

            .Row{
                display : flex;
                width: max-content;
                font-size: 0.75rem;

                &.Header{
                    font-size: 0.875rem;
                    font-weight: 700;
                    color: rgb(255,255,255);
                    background-color: rgb(25,24,27);
                }
                &:not(.Header ){
                    background-color: rgb(246, 246, 246);
    
                    &:nth-child(odd){
                        background-color: rgb(230,230,230);
                    }
                }
                
                .Cell{
                    padding: 6px 12px;
                    
                    &:first-child{
                        width: 80px;
                        flex-shrink: 0;
                    }
                    &:nth-child(2) {
                        width: 300px;
                    }
                }
            }
        }
    }
    
}