.NDA{
    word-break: keep-all;
    text-align: left;
    margin: 20px;
    padding-left: 20px; padding-right: 20px; padding-top: 20px; padding-bottom: 50px;
    background-color: rgba(233,233,233,1);
}

.ButtonBox {
    display:flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 400px) {
        flex-direction: column;
    }

    @media (min-width: 401px){
        justify-content: flex-end;
        
        .Name {
            margin-left: 10px; margin-right: 10px; width: 100px;

            label {
                top: -8px;

                &.Mui-focused {
                    top: 0px;
                }
            }

        }
        .Button {
            width: 100px;
        }
    }
}