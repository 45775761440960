html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Hide scrollbar for Chrome, Safari and Opera */
div::-webkit-scrollbar {
    display: none;
}
body::-webkit-scrollbar {
    all: initial;
    height: .8rem;
    width: .8rem;
}
body::-webkit-scrollbar-thumb {
    background-color: rgb(150,150,150);
    border-radius: 1rem;
}


/* Hide scrollbar for IE, Edge and Firefox */
div {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
