.KakaoTalkList {
    padding-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: 720px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Body {
        position: relative;
        width: 350px;
        max-height: 740px;
        border: 1px black solid;
        margin:2px;

        .SearchBar {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 30px;
            padding: 0px 20px 10px 20px;

            font-size: 1rem;
            font-weight: bold;

            img {
                height: 100%;
                object-fit: contain;
            }
        }

        .ChatRows {
            padding: 0 20px 0 20px;
        }

        img.Notibar, img.BottomButtons {
            width: 100%;
            object-fit: contain;
            border-top: 0.5px rgb(222,222,222) solid;
        }
    }
    
    .ChatList, .Example {
        width: 350px;
        height: 740px;
        overflow: auto;
        object-fit: contain;

        &::-webkit-scrollbar {
            all: initial;
            height: .8rem;
            width: .8rem;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: rgb(150,150,150);
            border-radius: 1rem;
        }

        .ChatData {
            font-size: 0.9rem;
            background-color: rgba(22,22,22,0.1);
            margin: 5px;
            padding: 10px;

            .Members {
                .MemberData {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                }
            }

            .Title, .Preview, .UpdatedAt, .NumberOfUnread {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                .Label {
                    padding: 10px;
                }
            }
        }
    }

    .Example {
        font-size: 0.8rem;
        word-break: keep-all;

        img {
            width: 40px; height: 40px;
            padding: 10px;
            border-radius: 16px;
        }
    }
}