.TitleEdit {
    padding: 10px;
    max-width: 1500px;
    margin: auto;

    .HeaderBar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        word-break: keep-all;

        .Btns {
            white-space: nowrap;

            .Btn {
                width: 60px;
                padding: 5px;
                border-radius: 0;

                $BORDER_RADIUS: 5px;

                &:first-child {
                    border-top-left-radius: $BORDER_RADIUS;
                    border-bottom-left-radius: $BORDER_RADIUS;
                }
                &:last-child {
                    border-top-right-radius: $BORDER_RADIUS;
                    border-bottom-right-radius: $BORDER_RADIUS;
                }
            }
        }
    }

    .TitleEditContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .Col {
            padding: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            .MainThumbContainer {
                position: relative;
                width: 100%;
                border-radius: 5px;
                overflow: hidden;
                background-size: cover;
                background-position: center;
                padding-top: 100%;

                .MainThumbBackdrop {
                    position: absolute;
                    top:0; left:0;
                    width: 100%;
                    height: 100%;
                    backdrop-filter: blur(10px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .MainThumb {
                max-height: 300px;
                max-width: 300px;
                object-fit: contain;

            }

            .VisibilityInfo {
                padding: 7px;
                width: calc(100% - 14px);
            }

            .TextField {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .Slides {
                padding: 5px;
                width: calc(100% - 14px);
                height: 120px;
                white-space: nowrap;
                display: flex;
                flex-direction: row;
                align-items: center;
                overflow-x: auto;
                overflow-y: hidden;
                
                &::-webkit-scrollbar {
                    all: initial;
                    height: .8rem;
                    width: .8rem;
                }
                
                &::-webkit-scrollbar-thumb {
                    background-color: rgb(150,150,150);
                    border-radius: 1rem;
                }

                .SlideThumb {
                    position: relative;
                    flex-shrink: 0;
                    flex-grow: 0;
                    width: 100px; height: 100px; margin: 5px;
                    font-size: 3rem;

                    span {
                        color: rgba(233,233,233,1);
                    }
                }
            }

            .Ownerships {
                padding: 5px;
                width: calc(100% - 14px);
                height: 40px;
                white-space: nowrap;
                display: flex;
                flex-direction: row;
                align-items: center;
                overflow-x: auto;
                overflow-y: hidden;

                &::-webkit-scrollbar {
                    all: initial;
                    height: .8rem;
                    width: .8rem;
                }
                
                &::-webkit-scrollbar-thumb {
                    background-color: rgb(150,150,150);
                    border-radius: 1rem;
                }

                .Avatar {
                    width: 1rem;
                    height: 1rem;
                }

                .Label {
                    display: flex;
                    flex-direction: row;
                    justify-content: stretch;
                    align-items: stretch;

                    white-space: nowrap;
                    
                    .UserAvatar {
                        width: 1.5rem;
                        height: 1.5rem;
                        flex-shrink: 0;
                    }

                    .Text {
                        margin-top:auto; margin-bottom: auto;
                        margin-left: 5px;
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }

    .TitleDetailContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .EditingDetails{
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: stretch;
            width: 100%;
            max-width: calc(50% + 300px);
            margin: 10px 0;

            .DetailBox{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                padding: 11.5px 14px;
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 4px;
                position: relative;

                margin: 10px 0;
                box-sizing: border-box;

                &:hover{
                    border: 1px solid black;
                };
              
                &:focus-within {
                    border: 2px solid rgb(0,176,240);
                    padding: 10.5px 13px;
                    box-sizing: border-box;

                    .Label{
                        color: rgb(0,176,240);
                        top: -11px;
                        left: calc( 1.5% - 1px);
                    };
                };

                .Label{
                    position: absolute;
                    top: -10px;
                    left: 1.5%;
                    background-color: rgb(255,255,255);
                    padding: 0 5px;
                    font-size: 0.75rem;
                    color: rgb(117,117,117);
                };

                .TopicItem{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 7px;
                    margin: 0 3px;
                    background-color: rgb(220, 220, 220);
                    border-radius: 3px;
                    color: rgb(51,51,51);
                    font-size: 0.9rem;
                    height: 32px;
                    box-sizing: border-box;
                    flex: 0;

                    .Text{
                        display: flex;
                        align-items: baseline;
                    };

                    .DeleteTagButton{
                        cursor: pointer;
                        margin-left: 5px;
                        font-size: 0.8rem;
                    };
                };

                .MetaTagTable{
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
                    justify-content: center;
                    max-width: 600px;
                    margin: 0 auto;
                    width: 100%;
                    
                    .MetaBox{
                        margin: 20px 0;

                        .MetaLabel{
                            font-size: 1rem;
                            font-weight: bold;
                            margin-bottom: 20px;
                        };

                        label{
                            margin: 0;
                        }
                        .MuiButtonBase-root.MuiIconButton-root{
                            padding: 4px;
                            margin-right: 5px;
                        }
                    }
                }
            };

            .TopicList{
                position: absolute;
                top: calc(100% - 10px);
                left: 0;
                width: 100%;
                z-index: 9;

                .ListBackground{
                    display: grid;
                    justify-content: center;
                    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
                    padding: 20px;
                    height: 300px;
                    overflow: auto;
                    
                    &::-webkit-scrollbar {
                        all: initial;
                        height: .8rem;
                        width: .8rem;
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        background-color: rgb(150,150,150);
                        border-radius: 1rem;
                    }

                    .ConsonantTitle{
                        font-size: 1.5rem;
                        font-weight: bold;
                        max-width: 600px;
                        width: 100%;
                        margin: 0 auto;
                    }

                    .TopicsOfConsonant{
                        display: flex;
                        flex-direction: column;

                        .TopicItem{
                            cursor: pointer;
                        };

                        .TopicItem.Disabled{
                            pointer-events: none;
                            color: rgb(196,196,196);
                        }
                    }
                }
            }
        };
    }
}

@media (min-width: 720px) {
    .TitleEdit {
        .TitleEditContainer {
            flex-direction: row-reverse;
            align-items: stretch;

            .Col {
                width: 50%;

                &.WithThumb {
                    max-width: 300px;
                }
            }
        }
    }
}

@media (max-width: 719px) {
    .TitleEdit {
        .TitleEditContainer {
            flex-direction: column;

            .Col {
                width: 100%;
            }
        }
    }
}