.BucketRoute {
    padding-top: 20px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    gap: 3px;
}

.BucketName {
    font-size: 40px;
    padding: 10px 20px 20px;
}

.DropZone {
    position: relative;
    width: 100%;
    height: 200px;
    overflow-y: auto;
    border-radius: 10px;
    border: 1px rgb(222,222,222) solid;
    cursor: pointer;
    
    &:hover {
        background-color: rgb(244,244,244);
    }

    .MuiInput-root {
        display: none;
    }
}

.UploadButton {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
}

.ObjectTable {
    width: 100%;

    .TableHeaderArea {
        padding: 20px;

        .ObjectCountArea {
            
            padding-bottom: 10px;
            font-size: large;
            span {
                font-weight: 700;
            }
    
            .Count {
                font-weight: 400;
                color: #687078;
            }
    
        }

        .ButtonArea {

            padding-bottom: 10px;
            
            .Button {
                height: 30px;
                cursor: pointer;
                border: 1px solid #545b64;
                color: #545b54;
                background-color: #fff; 
                padding: 2px 10px;
                box-sizing: border-box;
                margin-right: 10px;
                display: flex;
                align-items: center;
                border-radius: 2px;
            }

            .Disabled {
                cursor: default;
                border-color: #d5dbdb;
                background-color: #fff;
                cursor: default;
                color: #aab7b8;
            }

            .Enabled {
                color: #545b54;
                background-color: #fff; 
                
                &:hover {
                    color: black;
                }
            }
        }

        .SearchAndPaginationArea {
            display: flex;
            justify-content: space-between;

            .SearchBox {
                display: flex;
                align-items: center;
                background-color: #FFFFFF;
                border: 1px solid #687078;
                border-radius: 2px;
                height: 30px;
                padding: 2px 10px;
                box-sizing: border-box;
                gap: 3px;

                input {
                    &:focus {
                        outline: none;
                    }

                    border: none;
                    border-color: transparent;
                    background-color: inherit;
                }
            }

            .Pagination {
                display: flex;
                align-items: center;
                gap: 10px;
                .Page {
                    &:hover {
                        cursor: pointer;
                    }    
                }

                .Selected {
                    font-weight: 700;
                }
            }
        }
    }

    .ObjectTableArea {

        table {
            border-collapse: collapse;
            text-indent: initial;
        }

        thead {
            display: table-header-group;
            vertical-align: middle;
            border-color: inherit;
        }
        
        tr {
            display: table-row;
            height: 100%;
            vertical-align: inherit;
            align-items: center;

            th {
                border-right: 1px solid black;
                width: 20%
            }
        }

        td {
            height: 100%;
            padding: 5px;
        }

        .SelectButtonArea {
            width: 50px;
        }

        tr.Checked {
            border-top: 1px solid #0073bb;
            border-bottom: 1px solid #0073bb;    
        }

        .Checked {
            background-color: #f1faff;
          
            td:first-child {
                border-left: 1px solid #0073bb;
            }

            td:last-child {
                border-right: 1px solid #0073bb;
            }
        }

        .FileRow {
            input {
                cursor: pointer;
            }
        }

        .ObjectNameInner {
            display: flex;
            align-items: center;

            .FolderName {
                text-decoration: none;
                color: #0073bb;
            
                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                    text-decoration-color: #0073bb;
                }
            }
        }
    }
}