@import './IntroAnimation';

$ANIMATION_TIMING_FUNCTION: ease-in-out;
$ANIMATION_DURATION: 0.8s;

.IntroSection{
    max-width: 1200px;
    height: 100vh;

    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .IntroTextWrapper{
        display: flex;
        align-items: center;
        justify-content: center;

        flex-direction: row;
        gap: 32px;
        
        overflow: visible;
        
        @media (max-width:960px) {
            width: 100%;
            flex-direction: column;
            gap: 20px;
    
            overflow: hidden;
        }

        .MainText, .ServiceCarousel{
            height: 86px;
    
            font-style: normal;
            font-size: 4.5rem;
            letter-spacing: 1.08px;            
            
            line-height: 120%;
            font-family: Pretendard;
            
            transition: all $ANIMATION_DURATION $ANIMATION_TIMING_FUNCTION;
            
            @media (min-width: 431px) and (max-width: 1200px) {
                height: 58px;
                
                font-size: 3rem;
                letter-spacing: 0.72px;
            }
            
            @media (max-width: 430px) {    
                height: 48px;
                
                font-size: 2.5rem;
                letter-spacing: 0.6px;
            }
        }
        
        .MainText{
            display: inline-block;

            font-weight: 600;
            word-wrap: break-word;
            word-break: keep-all;

            color: rgba(60, 70, 81, 1);

            &:first-child{
                text-align: right;
            }
        }

        .ServiceCarousel{
            display: flex;
            flex-direction: column;            
            
            position: relative;
            
            padding: 0;
            margin: 0;
            
            list-style: none;
            
            text-align: center;
            font-weight: 800;
            
            white-space: nowrap;

            &.Studio            {width: 392px;}     
            &.Serial            {width: 538px;}     
            &.DataAnalysis      {width: 638px;} 
            &.AdManagement      {width: 464px;} 
            &.MarketingDashBoard{width: 464px;}
            @media (min-width:960px) and (max-width:1200px) { 
                &.Studio                {width: 262px;}     
                &.Serial                {width: 359px;} 
                &.DataAnalysis          {width: 425px;} 
                &.AdManagement          {width: 310px;} 
                &.MarketingDashBoard    {width: 310px;}
            }
            
            @media (max-width: 960px) {
                &.Studio                {width: 262px;}     
                &.Serial                {width: 359px;} 
                &.DataAnalysis          {width: 425px;} 
                &.AdManagement          {width: 310px;} 
                &.MarketingDashBoard    {width: 310px;}

                .ServiceNameItem{
                    &.Studio                {width: 262px;}     
                    &.Serial                {width: 359px;} 
                    &.DataAnalysis          {width: 425px;} 
                    &.AdManagement          {width: 310px;} 
                    &.MarketingDashBoard    {width: 310px;}
                }
                flex-direction: row-reverse;
            }

            .ServiceNameItem{                
                display: flex;
                align-items: center;
                justify-content: center;
                
                position: absolute;
                top: 0; bottom: 0; right: 0; left: 0;
                
                width: 100%;
                
                overflow: visible;
                
                white-space: nowrap;
                
                text-align: center;        
                
                transition: all $ANIMATION_DURATION $ANIMATION_TIMING_FUNCTION;

                animation: $ANIMATION_DURATION $ANIMATION_TIMING_FUNCTION forwards;

                &.FadeOut   {@include animationName(pcSlideFadeOut)}
                &.Prev      {@include animationName(pcSlidePrev)}
                &.Active    {@include animationName(pcSlideActive)}
                &.Next      {@include animationName(pcSlideNext)}
                
                &.None{display: none;}
                
                @media (min-width: 769px) and (max-width: 1200px) {
                    &.FadeOut   {@include animationName(labtopSlideFadeOut)}
                    &.Prev      {@include animationName(labtopSlidePrev)}
                    &.Active    {@include animationName(labtopSlideActive)}
                    &.Next      {@include animationName(labtopSlideNext)}
                }
                
                @media (min-width: 431px) and (max-width: 960px) {
                    &.FadeOut   {@include animationName(tabletSlideFadeOut)}
                    &.Prev      {@include animationName(tabletSlidePrev)}
                    &.Active    {@include animationName(tabletSlideActive)}
                    &.Next      {@include animationName(tabletSlideNext)}
                }
                
                @media (max-width: 430px) {
                    &.FadeOut   {@include animationName(mobileSlideFadeOut)}
                    &.Prev      {@include animationName(mobileSlidePrev)}
                    &.Active    {@include animationName(mobileSlideActive)}
                    &.Next      {@include animationName(mobileSlideNext)}
                }

                .ServiceName{
                    display: inline-flex;

                    height: 100%;

                    align-items: center;

                    background: linear-gradient(135deg, rgb(156, 173, 226) 0%, rgb(61, 106, 255) 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;        
                }
            }
        }
    }
}