

.InquiryTemplate{
    padding: 100px 0px;

    .InquiryBody{
        max-width: 500px;
        margin: auto;
        padding:0px 20px;
        vertical-align: top;

        .FormTitle{
            padding-bottom: 30px;
            font-size: 3rem;
        }

        .Labels{
            padding-top: 20px;
            padding-bottom: 3px;
            display: block;
            font-size: 15px;
        }

        .SubLabels{
            font-size: 11px;
            color: #bdbdbd;
        }

        .EachInput{
            width: 100%;
            height: 40px;
            padding: 10px 15px;
            font-size: 14px;
            color: #b0b0b0;
            border: 1px solid #bdbdbd;
            box-sizing: border-box;
            border-radius: 4px;
        }

        .InquiryText{
            width: 100%;
            height: 140px;
            padding: 10px 15px;
            border: 1px solid#bdbdbd;
            box-sizing: border-box;
            resize: none;
            border-radius: 4px;
        }

        .Slides{
            padding: 5px;
            width: calc(100% - 12px);
            height: 120px;
            white-space: nowrap;
            display: flex;
            flex-direction: row;
            align-items: center;
            overflow-x: auto;
            overflow-y: hidden;
        
            &::-webkit-scrollbar {
                all: initial;
                height: .8rem;
                width: .8rem;
            }
            
            &::-webkit-scrollbar-thumb {
                background-color: rgb(150,150,150);
                border-radius: 1rem;
            }

            .SlideThumb {
                position: relative;
                flex-shrink: 0;
                flex-grow: 0;
                max-width: 100px; min-width: 100px; height: 100px; margin: 5px;
                font-size: 3rem;

                span {
                    color: rgba(233,233,233,1);
                }
            }
        }

        .SubmitArea{
            margin-top: 30px;
            display: flex;
            flex-direction: row;
            justify-content: center;

            .SubmitButton{
                padding: 12px 40px;
                font-size: 16px;
                font-weight: 500;
                border: 0;
                background-color: rgb(0, 176, 240);
                color: rgb(255, 255, 255);
                border-radius: 4px;
            }

        }
    
    }
}
