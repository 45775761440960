.IntroductionPage{
    .HomeHeader {
        position: fixed;
        top: 0;
        z-index: 1;
    
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
    
        background-color: rgba(255, 255, 255, 1);
    
        .HeaderContentContainer{
            display: flex;
            align-items: center;
    
            .WebtoonTodayLogo{
                cursor: pointer;
                height: 24px;
    
                img{
                    height: 24px;
                    object-fit: contain;
                }
            }
        }
    }
    
    section {
        &.GrayColorGg {
            background-color : rgba(249, 250, 251, 1);
        }
    
        .IntroductionCardContainer {
            max-width: 1200px;
            
            padding: 80px 100px;
            margin: 0 auto;
            
            @media (min-width: 431px) and (max-width: 1024px) {
                padding: 80px calc(100% * 0.04);
            }
            
            @media (max-width: 430px) {
                padding: 40px 16px;
            }
        }
    }
}