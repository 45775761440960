
$SIGNATURE_COLOR: rgb(0, 176, 240);

.AnalyticsHome {
    width: 100%;
    height: 100%;
    
    .AppBar {
        background-color: $SIGNATURE_COLOR;
        
        .MuiToolbar-gutters {
            padding-left: 20px;
            padding-right: 20px;

        }

        .ArrowRight {
            transition: 200ms;
        }

        &.Opened {

            .ArrowRight {
                transform: rotate(-180deg);
            }
        }
    }

    .AppbarShadow {
        position: sticky;
        top: 0;
        width: 100%;
        height: 48px;
    }

    .Sidebar {
        position: fixed;
        top: 0;
        height: 100%;
        overflow-x:hidden;
        flex-shrink: 0;
        white-space: nowrap;
        transition: 400ms;
        
        &.Opened {
            width: 200px;
        }

        &.Closed {
            width: 56px;

            .Profile {
                .Name {
                    display: none;
                }
            }
        }

        .MuiDrawer-paper {
            overflow-x:hidden;
            position: relative;
        }

        .Profile {
            .Image {
                width: calc(100% - 10px);
                padding: 5px;
                border-radius: 50%;
            }
            .Name {
                padding: 5px;
                text-align: center;
            }
        }

        .ArrowRight {
            transition: 200ms;
        }
    }

    .Container {
        width: 100%;
        transition: 200ms;

        &.Narrow {
            padding-left: 200px;
            width: calc(100% - 200px);
        }

        &.Wide {
            padding-left: 56px;
            width: calc(100% - 56px);
        }
    }
}