.ReviewList {
    background-color: rgba(244,244,244,1);

    .ControlBox {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        padding: 10px;

        .Select {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .ReviewListContainer {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
    }
    .ReviewListTail {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .NoReviewWarning {
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            svg {
                width: 50px;
                height: 50px;
                padding: 20px;
            }
        }
    }
}