.Endpage {
    position: relative;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .Body {
        max-width: 690px;
        margin-left: auto; margin-right: auto;
        padding-bottom: 60px;
        img {
            width: 100%;
            
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;

            display: block;
        }

    }
    .ReviewInShort {
        margin: 1rem;
    }
}