$SIGNATURE_COLOR: rgb(0, 176, 240);

@media (max-width: 460px) {
    
    html:not([data-scroll='0']):not([data-scroll-remainder='0']):not([data-scroll-remainder='-0.5']) {

        .SiteHeader.ForceToShow {
            .MenuButton {
                background-image: url('https://static.webtoon.today/ddah/logo-symbol-white.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: 20rem 20rem;
            }
        }
    }
    
    .SiteHeader {
        .MenuButton {
            height: 2rem;
            width: 2rem;
            padding: 0;
            background-image: url('https://static.webtoon.today/ddah/logo-symbol-white.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 20rem 20rem;

            &.MuiButton-root {
                min-width: 2rem;
            }
        }
    }
    
    .BottomButtonBar {
        .Button {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

@media (min-width: 461px) {
    
    html:not([data-scroll='0']):not([data-scroll-remainder='0']):not([data-scroll-remainder='-0.5']) {

        .SiteHeader.ForceToShow {
            .MenuButton {
                background-image: url('https://static.webtoon.today/ddah/logo-horizontal-whitetext.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: 24rem 12rem;
            }
        }
    }
    
    .SiteHeader {
        .MenuButton{
            height: 4rem;
            width: 8rem;
            padding: 0;
            background-image: url('https://static.webtoon.today/ddah/logo-horizontal-whitetext.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 24rem 12rem;
        }
    }
    
    .BottomButtonBar {
        .Button {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}

html:not([data-scroll='0']):not([data-scroll-remainder='0']):not([data-scroll-remainder='-0.5']) {
    .SiteHeader {
        height: 2rem;
        background-color: white;
        color: black;

        &.HideClean {
            height: 0rem;
            overflow-y: hidden;
            color: white;
            z-index: inherit;
        }
        
        &.ForceToShow {
            height: 3rem;
            background-color: $SIGNATURE_COLOR;
            color: white;
        }
    }
    .BottomButtonBar {
        height: 2rem;
        background-color: white;

        &.HideClean {
            height: 0rem;
            overflow-y: hidden;
        }
        
        &.ForceToShow {
            height: 3rem;
            background-color: $SIGNATURE_COLOR;
            color: white;
        }
    }
    .Logo {
        display: none;
    }
}

html[data-scroll='0'], html[data-scroll-remainder='0'], html[data-scroll-remainder='-0.5'] {
    .SiteHeader {
        height: 3rem;
        background-color: $SIGNATURE_COLOR;
    }
    .BottomButtonBar {
        height: 3rem;
        background-color: $SIGNATURE_COLOR;
    }
    .LogoSmall {
        display: none;
    }
    .MenuButton {
        color: white;
    }
}

.SiteHeader {
    color: white;
    position: fixed; top:0; left: 0;
    width: calc(100% - 2rem);
    transition: 300ms;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 99;
    pointer-events: all;
    
    .Logo {
        height: 100%;
    }
    .LogoSmall {
        height: 80%;
    }
}

.BottomButtonBar {
    color: white;
    position: fixed; bottom:0; left: 0;
    width: calc(100% - 2rem);
    transition: 300ms;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 99;
    pointer-events: all;
    
    .Button {
        color: white;
        font-size: 1.1rem;

        &.Left {
            justify-content: flex-end;
        }

        &.Right {
            justify-content: flex-start;
        }

        svg {
            border-radius: 50%;
            border: 1px white solid;
            margin-left: 10px;
            margin-right: 10px;
        }

        &.NoCircle svg {
            border: none;
        }
    }

}

.PopupMenu {
    //height: 300px;
    width: 80%;
    max-width: 400px;
    background-color: rgba(230,230,230,0.3);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    transition: 300ms;
    pointer-events: all;
    overflow: hidden;

    position: absolute;
    left: calc(50% - 200px);
    bottom: -100%;

    &.Opened{
        bottom: 48px;
    }
}
.cls-1 {
    fill: rgba(0,0,0,0);
}
.cls-2 {
    fill: white;
}