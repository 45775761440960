$MARGINUNIT : 16px;

.PrelaunchBG {
    background-color: #000;
}

.PrelaunchPage{
    background-color: rgb(43,43,43);
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    .PrelaunchArea{
        color: white;

        .BodyContainer{
            padding: 0 $MARGINUNIT*2;

            .Section{

                .Line{
                    padding: $MARGINUNIT*5 0 $MARGINUNIT*3; 

                    .LineText{
                        margin: 0 -4px 10px -4px;
                        text-align: center;
                        font-weight: bold;
                    }

                    .RestText{
                        line-height: 24px;
                    }
                }

                .MainText{
                    text-align: center;
                    white-space: pre-line;
                    font-size: 0.9rem;
                    line-height: 30px;
                }

                .ButtonBox{
                    margin-top: $MARGINUNIT*1.5;

                    Button{
                        background-color: white;
                    }
                }

            }

            .GenderBox{

                .BoxInner{
                    display: flex;
                    justify-content: center;

                    .ToggleBackground{
                        border: 2px solid white;
                        border-radius: 13px;
                        background-color: rgb(64,64,64);
                        display: flex;
                        align-items: center;

                        .ToggleButton{
                            width: 50%;
                            color: rgb(125, 125, 125);
                            border-radius: 10px;
                            height: 100%;
                        }

                        .Focused{
                            background-color: white;
                            color: #2b2b2b;
                        }
                    }
                }
            }

            .AgeBox{
                margin-top: $MARGINUNIT*4.5;

                .MuiSlider-mark{
                    height: 24px;
                    margin-top: -8px;
                }

                .AgeButtonContainer{
                    display: flex;
                    flex-direction: row;
                    
                    .ButtonWrapper{
                        width: calc(20% - 4px);
                        display: flex;
                        justify-content: center;

                        .AgeButton{
                            color: white;
                            border-radius: 50%;
                            border: 2px solid white;
                            width: 45px;
                            height: 45px;
                            min-width: 0;
                            padding: 3px;
                            font-size: 0.8rem;
                            line-height: 1.12;
                            font-size: 0.75rem;
                        }

                        .Focused{
                            color: black;
                            background-color: white;
                        }
                    }

                }
            }

            .WebtoonFrequencyBox{
                margin-top: $MARGINUNIT*4.5;

                &:hover{
                    background-color: transparent;
                }

                .FrequencyInner{
                    position: relative;

                    .RadioGroup{
                        flex-direction: row;
                        width: 100%;

                        .ButtonWrapper{
                            width: 25%;
                            margin: 0;

                            .Label{
                                font-size: 0.8rem
                            }

                            .FrequencyButton{
                                color: white;
                                padding: 0;
                                background-color: rgb(43,43,43);
                                z-index: 1;
                                margin-bottom: 5px;
                            }

                            .MuiFormControlLabel-label{
                                font-size: 0.9rem;
                            }
                        }

                        .FocusColor{
                            color: white;
                            
                            .MuiFormControlLabel-label{
                                font-weight: bold;
                            }
                        }
                        .unFocusColor{
                            color: rgba(255,255,255,0.54);
                        }
                    }
                }
            }

            .FlatformBox{
                margin-top: $MARGINUNIT*4.5;

                .GridTemplate{
                    display: grid;
                    grid-template-columns: 1fr 1fr;

                    Label{
                        margin: 0;
                        .MuiTypography-body1{
                            margin-left: 5px;
                        }
                    }
                }
            }

            .BoxLabel{
                margin-bottom: $MARGINUNIT;
            }

            .EmailSection{
                padding-top: $MARGINUNIT*4.5;
                padding-bottom: $MARGINUNIT*2;
                
                .Title{
                    line-height: 24px;
                    margin-bottom: $MARGINUNIT;
                }

                .InputField{
                    display: flex;
                    justify-content: center;
                    margin-top: 5px;
                    position: relative;

                    .EmailField{
                        border: 1px white solid;
                        width: 75%;
                        box-sizing: border-box;

                    }
                    .EmailFieldPlaceHolder {
                        position: absolute;
                        font-size: 0.8rem;
                        left: 5px; top: 10px;
                        color: rgba(255,255,255,0.64);
                        opacity: 1;
                        transition: 100ms;

                        &.Focused {
                            opacity: 0;
                        }
                    }
                    .RegistButton{
                        background-color: white;
                        border-radius: 0;
                        width: 25%;
                        box-sizing: border-box;
                    }
                }
            }
        }
        
        .CopyrightSubRoot{
            text-align: center;
            padding: 50px 0px 100px;
            position: relative;
            background: rgb(43, 43, 43);
        
        
        
            .Copyrights{
                display: flex;
                flex-direction: column;
                justify-content: stretch;
                align-items: stretch;
        
                .Address{
                    padding-top: 20px;
                    word-break: keep-all;

                    a{
                        color: inherit;
                    }
                }
            }

        
        
        }
    }
}