.ItemDetailBox {
    @media (max-width: 720px) {
        width: calc(100% - 10px);
        margin: 5px;

        .Banner {
            width: calc(100% - 2px);
            padding-top: calc(100% * 9 / 16);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            
            border: 1px lightgray solid;
        }

        .Other {
            .TextInfo {
                .Title {
                    font-size: 1.5rem;
                    margin-top: 5px;
                    margin-bottom: 0.5rem;
                }
                .Tags {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    overflow-x: scroll;
                    text-overflow: ellipsis;

                    .Chip {
                        margin: 2px;
                        height: 26px;
                        span {
                            padding-left: 6px;
                            padding-right: 6px;
                        }
                    }
                }
                .Description {
                    margin-top: 10px;
                    margin-bottom: 10px;
                    font-size: 1rem;
                    line-height: 1.6;
                    color: #666;
                }
            }

            .Buttons {
                display: flex;
                flex-direction: row;

                .Button {
                    padding: 0;
                    margin: 5px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border-radius: 0;
                }
            }
        }

    }
    @media (min-width: 721px) {
        width: calc(100% - 10px);
        margin: 5px;

        display: flex;
        flex-direction: row;

        .Banner {
            width: calc(100% - 400px);
            padding-top: calc(100% - 400px);
            object-fit: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            
            border: 1px lightgray solid;
        }

        .Other {
            width: 380px;
            padding: 10px;
            .TextInfo {
                .Title {
                    font-size: 1.5rem;
                    margin-top: 5px;
                    margin-bottom: 0.5rem;
                }
                .Tags {
                    max-width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    overflow-x: scroll;
                    text-overflow: ellipsis;

                    .Chip {
                        margin: 2px;
                        height: 26px;
                        span {
                            padding-left: 6px;
                            padding-right: 6px;
                        }
                    }
                }
                .Description {
                    margin-top: 10px;
                    margin-bottom: 10px;
                    font-size: 1rem;
                    line-height: 1.6;
                    color: #666;
                }
            }

            .Buttons {
                display: flex;
                flex-direction: row;

                .Button {
                    padding: 0;
                    margin: 5px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    border-radius: 0;
                }
            }
        }
    }
}