
.NotFoundPage{
    .NotFoundArea{
        margin: 0 auto;
        max-width: 540px;
        padding-top: 150px;

        @media (max-width: 720px){
            padding-top: 100px;
        }

        .ImageArea{
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: stretch;
        }
        
        .PageTitle{
            color: rgb(0,176,240);
            font-size: 2.5rem;
            text-align: center;
            margin: 40px 0 10px 0;

            @media (max-width: 720px){
                font-size: 2rem;
            }
        }

        .PageText{
            text-align: center;
            font-size: 1rem;

            @media (max-width: 720px){
                font-size: 0.9rem;
            }

            .TextLine{
                padding: 1px;
            }
        }

        .ButtonArea{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: stretch;
            margin-top: 20px;

            @media (max-width: 720px){
                flex-direction: column;
                align-items: center;
            }

            a{
                margin: 10px;
                border-radius: 20px;
                width: 175px;

                @media (max-width: 720px){
                    margin: 5px;
                }
            }
        }
    }
}