.ReviewShort {
    all: initial;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &.haveRating {
        height: 60px;

        .User {
            width: 60px;
        }

        .ReviewBody {
            .Rating {
                margin-left: 0;
                margin-top: 10; margin-bottom: 0;
                width: 75px; height: 15px;

                .RatingContainer{
                    .RatingText {
                        display: inherit;
                    }
                }
            }

            .ReviewEdit {
                max-height: 200px;
            }

            .Review {
                max-height: 200px;
            }
        }
    }

    &.isWaiting {
        .ReviewBody {
            .ReviewEdit {
                display: inherit;
            }

            .Review {
                display: none;
            }
        }
    }

    &.isEditable {
        .ReviewBody {
            .Review {
                cursor: pointer;
            }
        }
    }

    .User {
        overflow-x: hidden;
        flex-shrink: 0;
        width: 0px;
    }

    .ReviewBody {
        width: 100%;

        .Rating {
            user-select: none;
            transition: 200ms;
            position: relative;

            margin-left: auto; margin-right: auto;
            margin-top: 10px; margin-bottom: 10px;
            width: 240px; height: 48px;

            .RatingContainer {
                position: relative;
                max-width: 100%;
                height: 100%;
                
                .RatingColor1 {
                    position: absolute;
                    left:0; top:0;
                    width: 100%;
                    height: 100%;
                    background-color: lightgray;
                }

                .RatingColor2 {
                    position: absolute;
                    left:0; top:0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(244,48,41,1);
                }

                .RatingMask {
                    position: absolute;
                    left:0; top:0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    .Img {
                        pointer-events: none;
                        width: 20%;
                        height: 100%;
                    }
                
                }

                .RatingText {
                    position: absolute;
                    display: none;
                    bottom:0; left: 77px;
                    color: rgba(0,0,0,0.45);
                    font-size: 0.6rem;
                
                }
            }
        }

        .ReviewEdit {
            position: relative;
            overflow-y: hidden;
            max-height: 0;
            display: none;

            .Title {all: initial; margin-left: 0; width: calc(100% - 60px - 80px); padding-right:80;}
            .Submit {
                position: absolute;
                top:0; right: 0;
                width:80;
                padding:0;
            }
        }
        .Review {
            position: relative;
            overflow-y: hidden;
            max-height: 0;

            .Title {all: initial; margin-left: 0; margin-right:10px};
            .ReviewBody {all: initial; };
            .Submit {
                position: absolute;
                top:0; right: 0;
                width:80;
            }
        }
    }
}