.AnalysisLaunching {
    max-width: 500px;
    .Thumbnail {
        width: 100%;
        object-fit: contain;
        vertical-align: middle;
    }
    .TitleSection {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 10px 0 10px;

        .Title {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .MuiAvatar-circle {
                width: 30px; height: 30px; margin: 5px;
            }
        }
    }

    table {
        margin: 10px;
        width: calc(100% - 20px);
        text-align: right;

        thead tr {
            background-color: rgb(133,133,133);
            color: white;
        }
    }
}