.GridWrapper{
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 40px 0;

    .TitleCardGrid {
        max-width: 980px;
        width: 100%;

        display: grid; 
        grid-gap: 24px;

        grid-template-columns: repeat(3, 1fr);
        
        @media (min-width: 767px) and (max-width: 1024px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .TitleCardContainer {
            display: flex;
            flex-direction: column;
            padding: 10px;
            border-radius: 30px;
            background-color: rgb(255,255,255);

            font-family: Pretendard;

            .IllustAnchor{
                padding: 0;
                border-radius: 20px;

                .Illust {
                    width: 100%;

                    aspect-ratio: 9 / 16;
                    border-radius: 20px;
                    object-fit: cover;
                    border: 1px solid rgb(237, 236, 238);
                    
                    &.LandScapeImage{
                        display: none;
                    }

                    @media (max-width: 1024px) {
                        aspect-ratio: 16 / 9;
                        &.LandScapeImage{
                            display: block;
                        }
                        &.PortraitImage{
                            display: none;
                        }
                    }
                }
            }
            .InfoWrapper {
                padding: 0 10px;

                word-wrap: break-word;
                word-break: break-all;
                
                .TitleName {                    
                    margin: 16px 0;
                    
                    color: rgba(30, 45, 62, 1);
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px; /* 155.556% */
                    letter-spacing: 0.18px;
                    text-transform: uppercase;
                    
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;                    
                    -moz-box-orient: vertical;                    
                }

                .InfoAndAction {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-end;
                    padding-bottom: 10px;
                    
                    .InfoItemList {
                        color: rgba(121, 120, 130, 1);

                        font-size: 1.125rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 28px; /* 155.556% */
                        letter-spacing: 0.18px;
                        text-transform: uppercase;
                        width: 100%;
                        flex-grow: 1;
                        flex-shrink: 1;

                        .InfoItem {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;       
                            -moz-box-orient: vertical;       

                            &.Author{
                                span:last-child::before {
                                content: ' / ';
                            }
                            }
                        }
                    } 
                    .ArrowButtonWrapper {
                        display: flex;
                        justify-content: flex-end;
                        padding-top: 20px;
                        flex-shrink: 0;
                        flex-grow: 0;

                        .ArrowButton {
                            padding: 8px;
                            background-color: rgba(60, 70, 81, 0.03);

                            img {
                                width: 32px;
                            }
                        }
                    }
                }
            }

        }
    }
}



