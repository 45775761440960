$SIGNATURE_COLOR: rgb(0,176,240);

div::-webkit-scrollbar{
    display: block;
}

body::-webkit-scrollbar{
    display: block;
}

.TeamAuthority{
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;

    .Title{
        font-size: 2rem;
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .AddTeamArea{
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        align-items: center;
        color: rgb(150,150,150);
    }

    .TeamAuthorityArea{

        .BelongTeams{
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            align-items: center;
            flex-wrap: wrap;

            .TeamBox{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin: 10px 10px 10px 0px;
                width: 240px;
                height: 270px;
                flex-wrap: wrap;
                position: relative;

                .EditIcon{
                    position: absolute;
                    top: 18px;
                    right: 5px;
                }

                .TeamName{
                    text-align: center;
                    font-size: 1.5rem;
                    width: calc(100% - 45px);
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow-x: hidden;
                    box-sizing: border-box;
                }

                .Chips{
                    width: 100%;
                    height: 65%;
                    overflow: auto;

                    &::-webkit-scrollbar {
                        all: initial;
                        height: .8rem;
                        width: .8rem;
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        background-color: rgba(150,150,150,0.5);
                        border-radius: 1rem;
                    }
                    .ChipBox{
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        text-align: center;
                        flex-wrap: wrap;
                        width: 100%;
                        padding-bottom: 6px;
    
                        .Chip{
                            margin: 5px 3px 0px 3px;

                            .Label {
                                .Text {
                                    max-width: 100px;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                }
                            }
                        }
                    }
                }

                .AddTeamBox{
                    height: 15%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    .AddButton{
                        border: 1px rgb(211,211,211) solid;
                        border-radius: 100%;
                    }
                }
                
                &:hover{
                    box-shadow: rgba(0,0,0,0.3) 0px 3px 7px;
                }

            }

            .AddNewCard{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 10px 10px 10px 0px;
                width: 240px;
                height: 270px;
                flex-wrap: wrap;
                
                .AddButton{
                    border: 1px rgb(211,211,211) solid;
                    border-radius: 100%;
                }

                .TeamText{
                    cursor: pointer;
                }

                &:hover{
                    box-shadow: rgba(0,0,0,0.3) 0px 3px 7px;
                }
            }

        }
    }

}