$BASIC_SPACE: 8px;
$BASIC_RADIUS: 15px;
$SIGNATURE_COLOR: rgb(0, 176, 240);


.MemberSubRoot{
    padding-top: 150px;
    position: relative;
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(252, 252, 252,1) 5%, rgba(251, 252, 252,1) 80%, rgba(255,255,255,1) 100%);
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;

    .MemberTitle{
        position: relative;
        margin-bottom: 50px;
        height: 100%;
        text-align: left;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 720px) {
            width: calc(100% - 40px);
        }

        .TeamTitle{
            font-size: 2.5rem;
            font-weight: bold;
            word-break: keep-all;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            padding: 0px;
            margin-bottom: 10px;
            
            @media(max-height: 720px){
                font-size: 2rem;
            }
        }

        .TitleFlex{
            
            text-align: center;
            font-size: 1.5rem;
            word-break: keep-all;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            
            span.Blue{
                margin: 0 0 0 0;
                color: $SIGNATURE_COLOR;
            }
        }

    }

    .MuiContainer-root.MuiContainer-maxWidthLg{
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .Section{
        text-align: center;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .SeperatedSection{
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            align-items: stretch;

            @media(min-width: 721px) and (max-width: 960px){
                align-items: center;
            }

            @media(max-width: 720px){
                display: grid;
                grid-template-columns: 1fr 1fr;
                justify-content: center;
                align-items: center;
                width: 100%;
            }

            .CardBox{
                height: 553px;
                padding: 20px 20px 0px 20px;

                @media(max-width: 720px){
                    padding: 5px 5px 0px 5px;
                    width: calc(100% - 10px);
                }

                .Cards{
                    width: 300px;
                        
                    @media(max-width: 720px){
                        width: auto;
                    }
                    
                    .Characters{
                        height: 300px;
                        background-color: rgba(226, 226, 226, 0.3);
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: flex-end;
                    }

                    .Introduction{
                        max-width: 100%;

                        .JobPositions{
                            display: flex;
                            flex-direction: row;
                            align-items: flex-end;
                            justify-content: center;
                            margin-top: 10px;

                            .Position{
                                font-size: 0.9rem;
                                color:rgba(158, 158, 158, 1);
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;

                                &.Hidden {
                                    color: rgba(0,0,0,0);
                                }
                            }
                            .Name{
                                font-size: 1.2rem;
                                font-weight: bold;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }

                        .UlStyles{     
                            padding-left: 10px;
                            margin: 0px;
                            list-style: none;

                            li{
                                display: grid;
                                grid-template-columns: auto auto 1fr;
                                padding: 10px 0px;
                            };

                            .SubText{
                                font-style: italic;
                                font-size: 0.5rem;
                                text-align: left;
                                padding-top: 3px;
                                padding-right: 7px;
                                min-width: 37px;
                                width: 37px
                            }

                            .Context{
                                white-space: nowrap;
                                overflow-x: hidden;
                                text-overflow: ellipsis;
                                text-align: left;

                                @media (max-width: 720px) {
                                    font-size: 0.875rem;
                                };

                                .SubContext{
                                    white-space: nowrap;
                                    overflow-x: hidden;
                                    text-overflow: ellipsis;
                                    text-align: left;
                                }

                                &.Multiline{
                                    line-height: 25px;
                                }

                                .Examples{
                                    font-size: 0.9rem;
                                }
                            }
                        }
                    }
                    
                    .CardIcon{
                        width: 250px;
                        height: 260px;
                        
                        @media(max-width: 720px){
                            width: calc(100% - 20px);
                            object-fit: contain;
                        }
                    }

                    
                }
                
            }
        }
    }

}
    
