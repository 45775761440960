$ANINATION_FAID_OUT_FONT_SIZE: 1rem;

$ANINATION_NEXT_TRANSLATE: -100%;

$ANINATION_PREV_MARGIN: -20px;
$ANINATION_NEXT_MARGIN: calc(72px + 40px);

$ANINATION_LAPTOP_NEXT_MARGIN: calc(48px + 32px);

$ANINATION_FAID_IN_TRANSLATE: 250%;
$ANINATION_FAID_OUT_TRANSLATE: -250%;

$ANIMATION_MOBILE_GAP: 64px;
$ANIMATION_MOBILE_MINUS_GAP: -64px;

@mixin mobileAnimatedText {    
    font-size: 2.5rem;
    letter-spacing: 0.6px;
    
    opacity: 0.2;
}

@mixin tabletAnimatedText {    
    font-size: 3rem;
    letter-spacing: 0.72px;
    
    opacity: 0.2;
}

@mixin labtopAnimatedText {
    height: 34px;

    font-size: 1.75rem;
    letter-spacing: 0.42px;
    
    opacity: 0.3;
}

@mixin pcAnimatedText {
    height: 48px;

    font-size: 2.5rem;
    letter-spacing: 0.6px;

    opacity: 0.3;
}

@mixin mobileActiveText {
    height: 48px;
            
    font-style: normal;
    font-size: 2.5rem;
    letter-spacing: 0.6px;    

    opacity: 1;
}

// tablet ~ laptop
@mixin activeText {
    height: 58px;

    font-size: 3rem;
    letter-spacing: 0.72px;
    
    opacity: 1;
}

@mixin pcActiveText {
    height: 86px;
    
    font-style: normal;
    font-size: 4.5rem;
    letter-spacing: 1.08px;

    opacity: 1;
}

@mixin keyframes ($animation_name) {
    @-webkit-keyframes #{$animation_name} {
        @content;
    }

    @-moz-keyframes #{$animation_name} {
        @content;
    }

    @-o-keyframes #{$animation_name} {
        @content;
    }

    @keyframes #{$animation_name} {
        @content;
    }
}

@mixin animationName ($animation) {
    -webkit-animation-name: $animation;
    -moz-animation-name: $animation;
    animation-name: $animation;
}

// 1200 ~
@include keyframes(pcSlideFadeOut) {
    0% {
        transform: translate3d(0, $ANINATION_NEXT_TRANSLATE, 0);
        top: $ANINATION_PREV_MARGIN;
        @include pcAnimatedText;
    }

    100% {
        transform: translate3d(0, $ANINATION_FAID_OUT_TRANSLATE, 0);
        height: 0;
        opacity: 0;
        font-size: $ANINATION_FAID_OUT_FONT_SIZE;
    }
}

@keyframes pcSlideNext {
    0% {
        height: 48px;
        transform: translate3d(0, $ANINATION_FAID_IN_TRANSLATE, 0);
        font-size: $ANINATION_FAID_OUT_FONT_SIZE;
        opacity: 0;
    }

    100% {
        top: $ANINATION_NEXT_MARGIN;
        @include pcAnimatedText;
    }
}

@keyframes pcSlideActive {
    0% {
        top: $ANINATION_NEXT_MARGIN;
        @include pcAnimatedText;
    }
    
    100% {
        @include pcActiveText;
    }
}

@keyframes pcSlidePrev {
    0% {
        @include pcActiveText;
    }
    
    100% {
        transform: translate3d(0, $ANINATION_NEXT_TRANSLATE, 0);
        top: $ANINATION_PREV_MARGIN;
        @include pcAnimatedText;
    }
}
// 1200 ~

// 768 ~ 1200
@keyframes labtopSlideFadeOut {
    0% {
        transform: translate3d(0, $ANINATION_NEXT_TRANSLATE, 0);
        top: $ANINATION_PREV_MARGIN;
        @include labtopAnimatedText;
    }

    100% {
        transform: translate3d(0, $ANINATION_FAID_OUT_TRANSLATE, 0);
        height: 0;
        opacity: 0;
        font-size: $ANINATION_FAID_OUT_FONT_SIZE;
    }
}

@keyframes labtopSlideNext {
    0% {
        height: 48px;
        transform: translate3d(0, $ANINATION_FAID_IN_TRANSLATE, 0);
        font-size: $ANINATION_FAID_OUT_FONT_SIZE;
        opacity: 0;
    }

    100% {
        top: $ANINATION_LAPTOP_NEXT_MARGIN;
        @include labtopAnimatedText;
    }
}

@keyframes labtopSlideActive {
    0% {
        top: $ANINATION_LAPTOP_NEXT_MARGIN;
        @include labtopAnimatedText;
    }
    
    100% {
        @include activeText;
    }
}

@keyframes labtopSlidePrev {
    0% {
        @include activeText;
    }
    
    100% {
        transform: translate3d(0, $ANINATION_NEXT_TRANSLATE, 0);
        top: $ANINATION_PREV_MARGIN;
        @include labtopAnimatedText;
    }
}
// 768 ~ 1200

// 430 ~ 768
@keyframes tabletSlideNext {
    0% {
        transform: translate3d($ANINATION_FAID_IN_TRANSLATE, 0, 0);
        @include activeText;
    }
    
    100% {
        transform: translate3d($ANIMATION_MOBILE_GAP, 0, 0);
        left: 100%;
        @include tabletAnimatedText;
    }
}

@keyframes tabletSlideActive {
    0% {
        transform: translate3d($ANIMATION_MOBILE_GAP, 0, 0);
        left: 100%;
        @include tabletAnimatedText;
    }
    
    100% {
        @include activeText;
    }
}

@keyframes tabletSlidePrev {
    0% {
        @include activeText;
    }
    
    100% {
        transform: translate3d($ANINATION_NEXT_TRANSLATE, 0, 0);
        left: $ANIMATION_MOBILE_MINUS_GAP;
        @include tabletAnimatedText;
    }
}

@keyframes tabletSlideFadeOut {
    0% {
        transform: translate3d($ANINATION_NEXT_TRANSLATE, 0, 0);
        left: $ANIMATION_MOBILE_MINUS_GAP;
        @include tabletAnimatedText;
    }
    
    100% {
        transform: translate3d($ANINATION_FAID_OUT_TRANSLATE, 0, 0);
        opacity: 0;
    }
}
// 430 ~ 768

// ~ 430
@keyframes mobileSlideNext {
    0% {
        transform: translate3d($ANINATION_FAID_IN_TRANSLATE, 0, 0);
        @include mobileAnimatedText;
    }
    
    100% {
        transform: translate3d($ANIMATION_MOBILE_GAP, 0, 0);
        left: 100%;
        @include mobileAnimatedText;
    }
}

@keyframes mobileSlideActive {
    0% {
        transform: translate3d($ANIMATION_MOBILE_GAP, 0, 0);
        left: 100%;
        @include mobileAnimatedText;
    }
    
    100% {
        @include mobileActiveText;
    }
}

@keyframes mobileSlidePrev {
    0% {
        @include mobileActiveText;
    }
    
    100% {
        transform: translate3d($ANINATION_NEXT_TRANSLATE, 0, 0);
        left: $ANIMATION_MOBILE_MINUS_GAP;
        @include mobileAnimatedText;
    }
}

@keyframes mobileSlideFadeOut {
    0% {
        transform: translate3d($ANINATION_NEXT_TRANSLATE, 0, 0);
        left: $ANIMATION_MOBILE_MINUS_GAP;
        @include mobileAnimatedText;
    }

    100% {
        transform: translate3d($ANINATION_FAID_OUT_TRANSLATE, 0, 0);
        opacity: 0;
    }
}
// ~ 430