

.MailReadLogDashboardContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .TitleArea {
        margin-bottom: 15px;
        
        .NameArea {
            display: flex;

            .DateArea {
                display: flex;
                border: 1px solid black;

                &.Read {
                    background-color: rgb(54,162,235);
                }
                &.Unread {
                    background-color: rgb(255,99,132)
                }
                
            }
        }
    }

}