$SIGNATURE_COLOR: rgb(0,176,240);

@mixin customFlex ( $direction: row, $justify: stretch, $align: stretch ) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
};

.KakaoTalkChat {
    @include customFlex(row, center, flex-start);
    padding-top: 100px;

    @media (max-width: 720px) {
        @include customFlex(column, center, center);
    }

    .ChatBody {
        position: relative;
        width: 350px;
        max-height: 740px;
        border: 1px black solid;
        margin:2px;

        .Header{
            @include customFlex(row, stretch, stretch);
            height: 22px;
            background: rgb(155,175,189);

            img{
                height: 23px;
                width: 100%;
                object-fit: contain; 
            }
        }

        .Top{
            @include customFlex(row, space-between, center);
            background-color: rgb(178, 199, 218);
            height:20px;
            padding: 15px;

            .Distinguisher{
                @include customFlex(row, center, center);
                height: 20px;

                img{
                    height: 70%;
                    object-fit: contain;
                }

                .ChatRoomName{
                    @include customFlex(row, center, center);
                    padding: 0px 0px 1px 20px;
                    font-weight: bold;

                    .MemberCounts{
                        color: 'rgb(50,50,50,0.4)';
                        margin-left: 5px;
                    }
                }
            }

            img{
                height: 70%;
                object-fit: contain;
            }
        }

        .Body{
            height: (740px - 114px);
            background-color: rgb(178, 199, 218);
            overflow-x: hidden;
            overflow-y: auto;
            padding: 0px 5px;

            &::-webkit-scrollbar {
                all: initial;
                height: .7rem;
                width: .7rem;
            }
            
            &::-webkit-scrollbar-thumb {
                background-color: rgb(139,154,166);
            }

            &::-webkit-scrollbar-thumb:hover{
                background: rgb(115,125,134);
            }

            .OwnerContainer, .GuestContainer{

                .MessageUserName{
                    margin-left: 6px;
                    font-size: 0.8rem;
                }
                
                .ChatBalloon{
                    position: relative;

                    .ReplyBalloon{
                        .Person{
                            font-size: 0.6rem;
                            font-weight: bold;
                            margin-bottom: 3px;
                        }
                        .SelectMessage{
                            font-size: 0.6rem;
                            color: rgba(50,39,0,0.7);
                        }
                        .Line{
                            margin: 5px -10px;
                            border-bottom: 0.5px solid rgba(113,98,8,0.1);
                        }
                        .ReplyText{
                            min-height: 30px;
                        }
                    }
                }
            }
            
            .OwnerContainer{
                position: relative;


                .ChatBalloon{
                    @include customFlex(row, flex-end, flex-end);
                    
                    .OwnerCountsAndTimes{
                        @include customFlex(column, stretch, flex-end);
                        font-size: 0.6rem;
                        margin-bottom: 4px;
                        line-height: 11px;

                        .unReadingPeopleCount{
                            color: yellow
                        }
                    }

                    .Owner{
                        @include customFlex(row, flex-end);
                        position: relative;
        
                        span{
                            margin: 3px 6px 3px 3px;
                            padding: 6px 10px;
                            background-color: rgb(250,225,0);
                            border-radius: 10px;
                            font-size: 0.8rem;
                            word-break: break-all;
                            max-width: 240px;
                        }
                    }
                    .DeleteOwnerChat{
                        position: absolute;
                        top: 2px;
                        right: -6px;
                    }
                }

                .timeLine{
                    width: 340px;
                    margin: 5px;
                    justify-content: center;

                    span{   
                        color: rgb(255,255,255);
                        margin: 2px 2px 2px 4px;
                        padding: 4px 8px;
                        background-color: rgb(157,176,191);
                        border-radius: 10px;
                        font-size: 0.6rem;
                        word-break: keep-all;
                    }
                }
            }
            
            .OwnerTail{
                position: relative;

                &::after {
                    content: "";
                    top: 13px;
                    position: absolute;
                    display: block;
                    right: -10px;
                    border: 0 solid transparent;
                    border-top: 9px solid rgb(250,225,0);
                    border-radius: 0 20px 0 20px;
                    width: 15px;
                    height: 30px;
                    transform: rotate(275deg) translateX(20px);
                }
            }

            .GuestContainer{
                display: flex;
                position: relative;

                .ChatBalloon{
                    @include customFlex(row-reverse, flex-end, flex-end);
                    font-size: 0.6rem;

                    .GuestCountsAndTimes{
                        @include customFlex(column, stretch, flex-start);
                        font-size: 0.6rem;
                        margin-bottom: 4px;
                        line-height: 11px;
                        
                        .unReadingPeopleCount{
                            color: yellow
                        }
                    }

                    .Guest{
                        @include customFlex(row, flex-start);
        
                        span{
                            margin: 3px 3px 3px 6px;
                            padding: 6px 10px;
                            background-color: rgb(255,255,255);
                            border-radius: 10px;
                            font-size: 0.8rem;
                            word-break: break-all;
                            max-width: 240px;
                        }
                    }
    
                    .DeleteGuestChat{
                        position: absolute;
                        top: 2px;
                        left: -4px;
                    }
                }

                .timeLine{
                    width: calc(340px - 62.66px);
                    justify-content: center;
                    margin: 5px;

                    span{
                        color: rgb(255,255,255);
                        margin: 2px 2px 2px 4px;
                        padding: 4px 8px;
                        background-color: rgb(157,176,191);
                        border-radius: 10px;
                        font-size: 0.6rem;
                        word-break: keep-all;
                    }
                }
            }

            .GuestTail{
                position: relative;
                
                &:after {
                    content: "";
                    top: -29px;
                    position: absolute;
                    left: -10px;
                    border: 0 solid transparent;
                    border-top: 9px solid #fff;
                    border-radius: 20px 0 20px 0;
                    width: 15px;
                    height: 30px;
                    transform: rotate(95deg) translateX(20px);
                }
            }
        }

        .Footer{
            height: 40px;
            
            img{
                height: 40px;
                width: 100%;
                object-fit: contain;
            }
        }
    }
    
    .AddArea{
        position: relative;
        width: 350px;
        max-height: 740px;
        margin:2px;
        box-sizing: border-box;
        overflow: auto;

        &::-webkit-scrollbar {
            all: initial;
            height: .8rem;
            width: .8rem;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: rgb(150,150,150);
            border-radius: 1rem;
            margin: 2px;
        }

        .Members {
            background-color: rgba(20, 20, 20, 0.1);
            margin: 2px 2px 5px 2px;
            padding: 10px;
            
            .MemberData {
                @include customFlex(row, center);
            }
        }

        .ChatOnwer{
            @include customFlex(row, flex-start, center);
            background-color: rgba(20, 20, 20, 0.1);
            margin: 2px 2px 5px 2px;
            padding: 10px;

            .Label {
                padding: 10px;
            }
        }

        .Title {
            @include customFlex(row, flex-start, baseline);
            background-color: rgba(20, 20, 20, 0.1);
            margin: 2px 2px 5px 2px;
            padding: 10px;

            .Label {
                padding: 10px;
            }
        }

        .Preview{
            @include customFlex(column, stretch, stretch);
            background-color: rgba(20, 20, 20, 0.1);
            margin: 2px 2px 5px 2px;
            padding: 10px;

            .SetWriter{
                @include customFlex(row);
                
                .Label{
                    padding: 10px;
                }
            }

            .MemberSelector{
                margin-bottom: 10px;

                .MuiSelect-select{
                    padding: 10px 32px 10px 10px;
                }
            }
        }

        .ChattingArea{
            padding: 5px;
            background-color: rgba(20, 20, 20, 0.1);
            margin: 2px 2px 5px 2px;

            .ChatBox{
                @include customFlex(column);
                padding-bottom: 15px;
                margin-bottom: 15px;
                border-bottom: 1px solid rgb(255,255,255);

                .UserInfomations{
                    @include customFlex(row);
    
                    .UserNameInChat{
                        margin-left: 6px;
                        font-size: 0.8rem;
                    }
                }

                .Chattings{
                    position: relative;

                    .ChattingOutline{
                        @include customFlex(row, space-evenly, center);
                    }

                    .TimePicker{
                        @include customFlex(column);
                        position: relative;
                        padding-left: 5px;
                        box-sizing: border-box;

                        .DeleteMessageButton{
                            position: absolute;
                            top: 1px;
                            right: -35px;
                        }
                        .AddMessageButton{
                            position: absolute;
                            top: 35px;
                            right: -35px;
                        }
                    }

                    .DeleteAddFunctions{
                        @include customFlex(column);
                    }

                    .AddChattingClickBox{
                        bottom: -28px;
                        left: -2px;
                        position: absolute;
                    }
                }
            }

            .LastChatBox{
                border: 0px;
                padding: 0px;
                margin: 0px;
            }

        }

    }

    .Example {
        font-size: 0.8rem;
        word-break: keep-all;
        width: 350px;
        height: 740px;
        overflow: auto;
        object-fit: contain;
        
        .ExampleTitle{
            padding-left: 10px;
            font-weight: bold;
        }

        img {
            width: 40px;
            height: 40px;
            padding: 10px;
            border-radius: 16px;
        }
    }
}

.KakaoChatNameLists{
    text-align: center;
    margin-bottom: 5px;
    cursor: pointer;
}
.KakaoChatTypeList{
    text-align: center;
    margin-bottom: 5px;
    cursor: pointer;
}
.KakaoChatTypeTimeLineHidden{
    display: none;
}
.KakaoChatDialogReplyList{
    max-height: 400px;
    overflow: auto;

        .KakaoChatDialogListReplyText{
            span{
                max-width: 450px;
                overflow-x: hidden;
                text-overflow: ellipsis;
            }
        }
}