$SIGNATURE_BG_COLOR: rgba(0, 176, 240, 0.2);
$SIGNATURE_COLOR: rgb(0, 176, 240);

@mixin customflex( $direction: row, $justify: stretch, $align: stretch ){
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

.SurveyBody {
    @include customflex(column, center, center);
    background-color: $SIGNATURE_BG_COLOR;
    padding: 20px;

    .SurveyContainer{
        max-width: 1000px;
        margin: auto;

        @media(max-width: 720px) {
            width: 100%;
        }

        .SurveyHeader{
            padding: 20px;
            margin-bottom: 20px;

            .HeadText{
                font-size: 1.5rem;
                text-align: center;
                margin-bottom: 10px;
                font-weight: bold;
            }

            .SubText{
                text-align: center;
            }
        }

        .EachBox{
            padding: 10px 10px 20px 20px;
            margin-bottom: 20px;

            @media (max-width: 720px) {
                padding: 5px 5px 10px 10px;
            }

            .BoxTitle{
                margin: 10px 0px 20px 0px;

                @media (max-width: 720px) {
                    margin: 5px 0px 10px 0px;
                }
    
                .SubTotalCount{
                    font-size: 0.8rem;
                }
            }

            .ColoredBackgroundArea{
                max-height: 400px;
                overflow: auto;

                .SuggestionText{
                    background-color: rgb(244, 245, 246);
                    font-size: 0.875rem;
                    padding: 10px;
                    margin: 0px 10px 10px 0px;
                    border-radius: 5px;
                }

                .CountSurveyText{
                    padding: 10px 0px;
                    font-weight: bold;
                    font-size: 0.8rem;
                    text-align: center;
                    margin-right: 10px;
                }
            }


            .ChartArea{
                padding-right: 10px;

                @media(max-width:720px){
                    padding-right: 5px;
                }
            }

            .Windows{
                max-height: 400px;
                overflow: auto;

                .StickyHeader{
                    position: sticky;
                    top: 0;
                    background-color: black;
                    margin-right: 10px;
                    width: calc(100% - 10px);

                    @media(max-width:720px){
                        margin-right: 5px;
                        width: calc(100% - 5px);
                    }
                    
                    .Box{
                        @include customflex(row, stretch, center);
                        width: calc(100% - 5px);
                        padding: 5px 5px 5px 0px;

                        .HeadCount{
                            @include customflex(row, center, center);
                            width: 5%;
                            color: white;
                            border: 0px;
                            min-width: 29px;
                        }
    
                        .HeadExplanation{
                            @include customflex(row, center, stretch);
                            width: 95%;
                            color: white;
                            border: 0px;
                        }
    
                        .AnalysisReason{
                            @include customflex(row, center, stretch);
                            width: 100%;
                            color: white;
                            border: 0px;
                        }
                    }
                }

                .Container{
                    border-radius: 0px;
                    margin-bottom: 10px;
                    margin-right: 10px;
                    width: calc(100% - 10px);

                    .ReasonArea{

                        .Reasons{
                            padding: 5px;

                            .Row{
                                @include customflex(row);
                                
                                .Count{
                                    @include customflex(row, center, center);
                                    width: 5%;
                                    min-width: 29px;
                                }

                                .Explanation{
                                    width: 95%;
                                    padding: 10px;

                                    @media (max-width: 720px) {
                                        padding: 10px 0px;
                                    }
                                }

                                .FullExplanation{
                                    width: calc(100% - 20px);
                                    padding: 10px;
                                }

                                .CreatedTime{   
                                    float: right;
                                    font-size: 0.8rem;
                                    color: rgb(210,210,210);
                                }
                            }
                        }
                    }
                }

                .CountSurveyText{
                    padding-top: 10px;
                    font-weight: bold;
                    font-size: 0.8rem;
                    text-align: center;
                    margin-right: 10px;
                }

            }
        }
    }
    
    div::-webkit-scrollbar {
        all: initial;
        height: .8rem;
        width: .8rem;
    }
    
    div::-webkit-scrollbar-thumb {
        background-color: rgb(150,150,150);
        border-radius: 1rem;
    }
}