$SIGNATURECOLOR: rgb(0, 176, 240);
$PUBLICSPACE: 20px;
$BASICRADIUS: 16px;

html {
    height: 100%;

    body {
        height: 100%;
        
        #root {
            height: 100%;

            .App {
                height: 100%;
            }
        }
    }
}

.Body{
    height: 100%;
    
    .Background{
        min-height: calc(100% - 48px);
        padding-top: 48px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 1200px;
        background: rgb(247,247,247);
        margin: auto;
        
        .Area{

            width: 100%;
            max-width: 720px;

            .ExtractInfo{
                position: relative;
                margin: $PUBLICSPACE;
                padding: $PUBLICSPACE * 2;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: rgba(0,0,0,0);

                .OutText{
                    background: rgb(247,247,247);
                    position: absolute;
                    top: -14px;
                    left: 20px;
                    padding: 4px;
                    font-size: 0.8rem;
                }

                .ImgArea{
                    padding: $PUBLICSPACE ;
                    display:flex;
                    justify-content: center;
                    align-items: stretch;

                    .Thumbnail{
                        border: 1px solid rgb(255, 255, 255);
                        border-radius: $BASICRADIUS;
                    }
                }
            
                .WebtoonInfo{
                    margin-bottom: $PUBLICSPACE;

                    .Title{
                        font-size: 2rem;
                        font-weight: bold;
                        text-align: center;
                        padding-bottom: $PUBLICSPACE;

                    }
                    .Description{
                        background-color: rgb(255, 255, 255);
                        border: 1px rgba(0, 0, 0, 0.12) solid;
                        border-radius: $BASICRADIUS;
                        padding: $PUBLICSPACE;
                    }
                }

                .Certificate{
                    font-size: 1rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    transition: 200ms;
                    overflow-y: hidden;

                    &.Hidden {
                        max-height: 0;
                    }

                    .Email{
                        margin: 10px;
                    }
                }

                .SubmitUrl {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .Input {
                        margin-bottom: 10px;
                    }
                }
            }

            .Check{
                margin:20px;
                padding-top: $PUBLICSPACE;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .CheckContainer {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;        
                }

                .DataConsentButton{
                    color: $SIGNATURECOLOR;
                }
            }

            .Logo {
                max-width: 40%;
                margin-left: 30%;
            }

            .Apply{
                padding: $PUBLICSPACE;
                display: flex;
                justify-content: center;

            }

            .ColoredButton{
                font-size: 1rem;
                width: 100px;

                &.Blue{
                    background-color: $SIGNATURECOLOR;
                }
            }

            .Rule{
                color: black;

                padding: 20px;
                margin: 20px;
            }
        }
    }

}