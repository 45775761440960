.TextBoxHeader {
    padding: 10px;

    .TitleText{
        font-size: 1.2rem;
        font-weight: bold;
    }
    .SubTitleText{
        font-size: 0.7rem;
    }
}
.TextBoxBody {
    padding: 10px;
    font-size: 1.1rem;
    .ContentText{
        font-size: 0.9rem;
    }
    .NextToBarText{
        font-size: 1rem;
        font-weight: bold;
    }
}

.TextBoxFooter {
    padding: 10px;
}