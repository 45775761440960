.HeaderContainer {
    padding-bottom: 60px;
    border-bottom: 1px solid rgba(213, 213, 216, 1);

    .HeaderTitle {
        display: inline-flex;
        align-items: flex-end;
        gap: 16px;

        color: rgba(25, 24, 27, 1);
        font-family: Pretendard;
        font-style: normal;
        font-weight: 600;
        text-transform: uppercase;
        
        font-size: 3.25rem;
        line-height: 120%; 
        letter-spacing: 0.78px;

        @media (min-width: 431px) and (max-width: 1024px) {
            font-size: 2.375rem;
            line-height: 100%; 
            letter-spacing: 0.57px;
        }

        @media (max-width: 430px) {    
            gap: 10px;
            
            font-size: 2rem;
            line-height: 100%; 
            letter-spacing: 0.48px;
        }

        .Dot {
            width: 16px;
            height: 16px;
            margin-bottom: 8px;
            border-radius: 50%;
            background-color: rgba(214, 214, 214, 1);
            
            @media (min-width: 431px) and (max-width: 1024px) {
                margin-bottom: 5px;
            }
            
            @media (max-width: 430px) {    
                width: 10px;
                height: 10px;
                margin-bottom: 5px;
            }
        }
    }
}