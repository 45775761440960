.InsightPoolArea{
    container-type: inline-size;
    .HeaderButtonBox{
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;

        .SelectingButton{
            &.Selected{
                background-color: lightgreen;
            }
        }
    }

    .SelectedTitlePreviewer{
        margin: 24px auto;
        max-width: 1024px;
        width: 100%;
        .OptionTableBody{
            color: rgb(51, 51, 51);
            .OptionTr{
                &:nth-child(odd){
                    background-color: rgb(238, 238, 238);
                }
                margin: 8px 0;
                .OptionTd{
                    padding: 8px;
                    &:first-child{
                        width: 100px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.DiagnoesEditorForm{
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 12px;

    max-width: 1024px;
    margin: 12px auto;

    background-color: rgb(255, 255, 255);

    .SelectOptionButtonGroup{
        justify-content: space-around;

        .OptionToggleButton{
            width: 100%;
            height: 100%;
            
            cursor: pointer;

            border-radius: 0;

            &.Disabled{
                background-color: rgb(221, 221, 221);
                color: rgb(255, 255, 255);
                cursor: default;
            }
        }
    }

    .DiagnosesOptionWrapper{
        display: none;
        
        &.Selected{
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
        .NumberInputField {
            input[type="number"]::-webkit-outer-spin-button,
            input[type="number"]::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }

    .DiagnoseEditorButton{
        cursor: pointer;

        &:disabled{
            cursor: default;
        }
    }
}