.Footer {
    padding: 40px 0 40px 0;
    margin: 0 auto;

    .FooterMenu {
        cursor: pointer;
        color: rgba(138, 138, 138, 1);
        text-align: center;
        line-height: 2rem;

        span {
            white-space: nowrap;
            margin: 0 10px 0 10px;
            span {
                white-space: nowrap;
                margin: 0 4px 0 0;
                img {
                    position: relative;
                    top: -2.6px;
                    transition: 200ms;
                }
                .Closed {
                    transform:rotate(180deg);
                    transition: 200ms;
                }
            }
        }
        
        a {
            text-decoration: none;
            color: inherit;
            span {
                margin: 0 10px 0 10px;
            }
        }

        .Pipe {
            cursor: default;
            color: rgba(217, 217, 217, 1);
        }

        .PrivacyPolicy {
            font-weight: 700;
            color: rgba(51,51,51,1)
        }
    }

    .CompanyDetail {
        margin-top: 16px;
        color: rgba(196, 196, 196, 1);
        text-align: center;
        
        .FirstLine {
            margin-bottom: 4px;

            span {
                margin: 0 5px 0 5px;
                white-space: nowrap;
            }
        }

        .SecondLine {

            span {
                margin: 0 5px 0 5px;
            }
        }

    }

    .CompanyName {
        color: rgba(196, 196, 196, 1);
        text-align: center;
        margin-top: 24px;
    }
}

