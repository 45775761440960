.IntroductionCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    font-family: Pretendard;

    .ServiceDetailContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        margin: 120px 0 32px 0;
        @media (max-width: 767px) {
            margin: 80px 0 32px 0;
        }

        .Chip {
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: 30px; /* 150% */
            letter-spacing: 1px;
            text-transform: uppercase;
            
            &.Blue  { color: rgba(61, 106, 255, 1); }
            &.Green { color: rgba(128, 178, 44, 1); }
            &.Sky   { color: rgba(14, 169, 246, 1); }
        }

        .ServiceName {
            margin-bottom: 16px;
            
            color: rgba(25, 24, 27, 1);
            
            font-style: normal;
            font-weight: 800;
            text-transform: uppercase;

            font-size: 2rem;
            line-height: 48px; /* 150% */
            letter-spacing: 0.48px;
            
            @media (min-width:431px) and (max-width: 767px) {
                font-size: 1.75rem;
                line-height: 42px;
                letter-spacing: 0.42px;
            }

            @media (max-width: 430px) {
                font-size: 1.5rem;
                line-height: 36px;
                letter-spacing: 0.36px;
            }
        }

        .ServiceDescription {
            color: rgba(121, 120, 130, 1);

            white-space: pre-line;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            letter-spacing: 0.16px;

            word-wrap: break-word;
            word-break: keep-all;

            @media (max-width:430px) {
                white-space: inherit;
            }
        }
    }  
     
    .LinkButton {
        padding: 12px;
        border-radius: 8px;
        background-color: rgba(60, 70, 81, 0.03);

        .TextWrapper {
            display: inline-flex;
            gap: 4px;

            .ButtonText {
                color: rgba(60, 70, 81, 1);
                font-family: Pretendard;
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */
                letter-spacing: 0.16px;
            }

            .ArrowRight {
                width: 20px;
                height: 20px;
                margin: auto;
            }
        }
    }

    .IntroductionImageWrapper {
        width: 100%;
        display: flex;

        justify-content: center;
        border-bottom: 1px solid rgba(213, 213, 216, 1);

        .IntroductionImage {
            width: 100%;
            max-width: 980px;
        }
    }
}