

$GRID_SIZE: 100px;
$GRID_MARGIN_RATIO:0.1;
$CARD_HEIGHT: 300px;

.FlatCardStyle.vertical {
    position: relative;
    padding: 0; margin: 5px;
    overflow: hidden;
    width: 150px;
    height: calc(#{$CARD_HEIGHT} - 40px);
    margin-bottom: 20px;

    flex-shrink: 0;

    display: flex;
    background-color: rgba(0,0,0,0.05);
    border-radius: 10px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    
    &.isActive {
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.30), 0 6px 12px rgba(0, 0, 0, 0.22);
        border-radius: calc(#{$GRID_SIZE} * #{$GRID_MARGIN_RATIO} / 2);
    };

    .Grid {
        width: 100%;
        height: calc(#{$CARD_HEIGHT} * 2/3);
        top: 0; left: 0;
        position: absolute;
        .Container {
            display: block;
            width: 100%;
            .ThumbnailContainer {
                overflow: hidden;
                display: flex;
                width: 100%;
                height: calc(#{$CARD_HEIGHT} * 2/3);
                .Thumbnail {
                    width: 100%;
                    object-fit: cover;
                };
            };
            .LabelContainer{
                position: relative;
                display:flex;
                align-items: center;
                margin: 10px;
                height: 40px;
                .ServiceAvatar {width: 20px; height: 20px; position: absolute; top: -20px; left: calc(50% - 30px - 10px); z-index:1};
                .Avatar {width: 40px; height: 40px; position: absolute; top: -40px; left: calc(50% - 30px)};
                .Labels {
                    margin-left: 0;
                    max-width: calc(100% - 20px);
                    .Title {font-size: 0.8rem; overflow-x:hidden; text-overflow: ellipsis; white-space: nowrap};
                    .Subtitle {font-size: 0.7rem; color: grey; overflow-x:hidden; text-overflow: ellipsis; white-space: nowrap};
                };
                .More {position: absolute; right: 0; display:none};
            }
        };
        .OpenGrid {
            & .isOpen {display: flex;}
            display: none;
            
            position: fixed;
            top: 0; left: 0;
            width: 100%; height: 100%;
            align-items: center;
            justify-content: center;
            background-color: rgba(0,0,0,0.3);
            z-index: 2;
            .Background {
                position: absolute;
                width: 100%;
                height: 100%;
            };
            .Card {
                z-index: 3;
                background-color: white;
                border-radius: 20px;
                overflow: hidden;
                width: calc(100% - 20px);
                max-width: 400px;
                .ThumbnailContainer {
                    .Thumbnail {width: 100%; max-height: 300; object-fit: cover};
                };
                .ThumbTitleContainer {
                    display: flex;
                    padding: 2;
                    .Avatar {padding:0; margin:2px; min-width: 40px};
                    .Title {padding: 8px};
                };
                .Description {
                    padding: 8px;
                    height: 2rem;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                    word-wrap: keep-all;
                    overflow: hidden;
                    font-style: italic;
                };
                .Actions {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    .Button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    };
                };
            }
        
        };
        
    };
    .BackGround {};
}
